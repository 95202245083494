<template>
  <div style="width:100%; height: 100%;">
    <div style="width:100%;height: calc(100% - 50px);overflow-y: auto;">
      <div style="width:100%;padding: 10px;">
        <span style="font-size:60px"><strong>NEWS</strong></span>
      </div>
      <div style="width:100%;">
        <div  v-if="News_Info.length>0"  style="margin-top:0px;padding: 10px;">
          <van-row gutter="8">
            <van-col v-for="(image, index) in News_Info" :key="index" span="12">
              <div @click="open_news(image.open_url)" style="width:100%;overflow: hidden;border: 1px solid #ddd; border-radius: 8px; margin-bottom: 8px;">
                <div style="width:100%; ">
                  <van-image width="100%" height="108px" fit="cover":src="image.url"/>
                </div>
                <div style="width:100%;">
                  <div style="padding-bottom:10px;white-space: nowrap;   overflow: hidden;  padding: 0px 8px;  text-overflow: ellipsis;">{{image.name}}</div>
                  <div style="width:100%;height:30px;display: flex;align-items: center;padding: 0px 2px;">
                    <div style="width:calc(100% - 60px);font-size:14px;  color:#9c9c9c; display: flex; align-items: center;">
                      <img src="../../../assets/icons/date.png" width="20px" style="margin-right:4px;"/><span>{{image.date}}</span>
                    </div>
                    <div style="width:60px; font-size:14px;  color:#9c9c9c; display: flex;align-items: center; justify-content: right;">
                      <img src="../../../assets/icons/look.png" width="30px"  style=""/> {{image.see_num}}
                    </div>
                  </div>
                </div>
              </div>
            </van-col>
          </van-row>
        </div>
      </div>
      <o_footer></o_footer>
    </div>
    <o_head></o_head> 
  </div>
</template>

<script>
  import o_footer  from '../../../components/pages/component/phone_footer.vue'
  import o_head  from '../../../components/pages/component/phone_onexz_head.vue'

  export default {
    props: {
      
    },
    
    mounted() {
      document.title = '资讯-品牌设计全案-广东万喜至工业设计有限公司'; // 设置页面标题
      window.addEventListener("scroll", this.btn_pos);      
      window.scrollTo(0, 0);
    },
    data() {
      return {       
        scrollTop: 0,
        show:0,       
        News_Info:[{"url":"http://www.zq-fa.com/oneXZ_official_website/news/new3.jfif","name":"产教对接·互促共赢 —工业设计高层次人才交流会暨校企合作签约仪式","date":"2023-12-25","see_num":"10","open_url":"https://mp.weixin.qq.com/s/4poMIV9vs7Fbg4ZjgmCgZw"},    
      {"url":"http://www.zq-fa.com/oneXZ_official_website/news/new2.jfif","name":"从概念到落地的创新设计研究","date":"2023-12-20","see_num":"20","open_url":"https://mp.weixin.qq.com/s/BGFcjn3YzlfDwj5QwAwM8w"},
      {"url":"http://www.zq-fa.com/oneXZ_official_website/news/new1.jfif","name":"万喜至再次获得荣誉 |2023东莞杯国际工业设计大赛","date":"2023-12-10","see_num":"96","open_url":"https://mp.weixin.qq.com/s/pxHhE7S9yZybOPh08lNxFQ"},
      
    ],
        
        num:0,
      }
    },
    created(){
      sessionStorage.li_num = 3;
    },
    components: {
      o_footer,o_head
    },
    methods: {
        btn_pos() {
          let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
          if (scrollTop > 400) {
            this.show=1;        
          } else {
            this.show=0;         
          }      
        },
        
      goToContact_work(imgs) {
        // 编程式导航到 / 路径   
        localStorage.setItem("onework_info",JSON.stringify(imgs));
        this.$router.push({name:'onework'});
      },
      open_news(url){
        window.open(url,'_blank');

      },
      
      activeIndex(Index){
          this.num= Index;

      },

    }
  }
</script>
<style>
  .container{
    min-height:100%;
    height: auto !important;
    height: 100%; /*IE6不识别min-height*/
    position: relative;
  }
  
  .page {   
    width:100%;
    margin: 0 auto;  
    margin-top:90px;
    color: #595959;
    font-size: 14px;
  }

  .heard_2 { width: 100%;   padding-top:30px; padding-bottom: 30px; position: fixed; top: 0; z-index: 1000;  padding-left: 5%; background-color: #fff;}
  .logo { float: left; height: 30px; }
  .heard_2  ul li{ float: left; padding: 0 20px 0 20px;list-style: none}
  .heard_2  ul li  {color: #000; font-size:17px; text-shadow: 0px 0px 2px #000000; cursor: pointer;}
  .heard_ul li:hover{ color:#DE303A;  text-shadow: 0px 0px 2px #DE303A;}
  .top_show{opacity: 0; top:-500px; display: none;}
  .top_hide{ opacity: 1; top:0px; display: block;}
  .ch{ display: none}


  .works_title{
    width: 100%;
    max-width: none;
    min-width: 0px;
    height: auto;
    max-height: none;
    min-height: 86px;
    margin-top: 130px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    padding-left: 5%; 
  }


  .latest_case {
    width: 100%;
    padding: 0;
    margin: 30px 0 15px;
    padding:0 5%;
}
.latest_case_more{
   color: #333;
    text-align: left;
    padding: 50px;
    font-weight: 600;
    font-size: 24px;
}
.owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
}

.owl-drag{ padding-bottom: 40px;}        
.p_Product{
        background-color: #fff;
        overflow:hidden; 
        position: relative; 
        display: inline-block;
        vertical-align: top;
        width: calc((100% - 0px) / 3) !important;
        border-width: 3px;
        border-style: solid;
        border-color:#fff;
        height: inherit;
        border-radius: 10px;
       transition: all .4s ease-in-out;

}
.latest_case_list{ width:100%; height: auto; float:left;overflow:hidden; }
.latest_case_pic{width: 100%; height: auto; float:left; overflow:hidden; }
.p_Product:hover .latest_case_title{ opacity:1;}
.p_Product:hover .h5_title{ animation: slide-up2 0.5s ease forwards; /* 动画名称，时长，缓动函数，填充模式 */}
.p_Product:hover img{ overflow:hidden; transform: scale(1.1);    transition: all 0.5s;}
   

      
.latest_case_title{position: absolute; top:0; left:0; width:100%; height: 100%;
        background-color: rgba(0,0,0,0.5);
        color:#fff;
        justify-content: center; /* 水平居中 */
        align-items: center; /* 垂直居中 */
        opacity: 0; /*初始时隐藏文本 */
       /* transition: opacity 0.3s ease-in-out; /* 平滑过渡效果 */
         transition: transform 0.5s linear;
      }
  /* 定义动画 */
  @keyframes slide-up2 {
      from {
        transform: translateY(10%); /* 从下边开始 */
        opacity: 0; /* 不透明度为0 */
      }
      to {
        transform: translateY(0); /* 移动到原位置 */
        opacity: 1; /* 完全不透明 */
      }
    }
    

.latest_case_title .h5_title{
  cursor: pointer;
  padding:0 10%  8% 10%;
  text-align: center;
  height: 100%;;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}


.prompt_title{
  width:230px;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}
.seemore_nodata{
  width:100%;
   display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  font-size:18px;
  font-weight: bold;
}
.font_tip{
  padding-left: 10px;
}





/*行业类别css*/

.hc-ul {
    background: #ffffff;
    height: 50px;
    position: relative;
    z-index: 9;
    /* border-style: solid; */
    display: flex;
    justify-content: space-evenly;
    border-radius: 7px;
    margin:0 auto;
  }
.hc-ul li {
    
    float: left;
    width: 230px;
    text-align: center;
    cursor: pointer;
    height: 40px;  
    position: relative;
    z-index: 999;
    list-style-type: none;
    padding: 14px 0 14px 14px;   
    font-size: 15px;
}
.hc-ul li div{
  border-right: 1px solid #dcdbdb; 
  padding: 0 14px 0 0;
}

.hc-ul li:hover{ color:#DE303A; }
.activetext{
  color:#DE303A;
  font-weight: bold;
}























</style>
