<template>
    <div style="width:100%;height:100%;">
		<div style="width:100%;">
			<div style="width:100%;padding: 10px; font-weight: bold;display: flex;">
				<span style="padding-right: 10px;">项目设计问卷</span>
			</div>
			<div style="width:100%;padding: 10px; background-color: #FFF;">
				<div style="width:100%;">
					<div style="width:100%;">尊敬的客户，感谢您对万喜至的信任与支持，为了更好的推进此项目，我方需要收集一些关于产品的基本信息，以便参考，请认真填写以下信息，谢谢！</div>
					<div style="width:100%;font-weight: bold;margin-top: 10px;">1、企业信息</div>
					<div style="width:100%;">
						<van-form @submit="onSubmit">
							<van-field 
								readonly 
								label="公司名称:"
								v-model="customInfo.uname"
							/>
							<van-field
								placeholder="(必填)"
								v-model="projectDesignQuestionnaire.companyWebsite" label="公司网站:"
							/>
							<van-field
								v-model="projectDesignQuestionnaire.receiveEmail"
								label="接收邮箱:"
								placeholder="(选填)"
							/>
						</van-form>
					</div>
				</div>
				<div style="width:100%;">
					<div style="width:100%;font-weight: bold;">2、产品信息</div>
					<div style="width:100%;">
						<van-form >
							<van-field 
								readonly 
								v-model="projectInfo.pname"
								label="产品名称:"
							/>
							<van-field
								v-model="projectDesignQuestionnaire.productSpec"
								label="产品规格:"
								placeholder="(选填)"
							/>
							<van-field v-model="projectDesignQuestionnaire.functionIntro" label="功能简介:" rows="4" autosize type="textarea" maxlength="1024" placeholder="(必填)" />
							<van-field v-model="projectDesignQuestionnaire.usageEnv" label="使用环境:" rows="4" autosize type="textarea" maxlength="1024" placeholder="(选填)" />
							<van-field v-model="projectDesignQuestionnaire.existingIssues" label="现存问题:" rows="4" autosize type="textarea" maxlength="1024" placeholder="(选填)" />
							<van-field v-model="projectDesignQuestionnaire.competitors" label="竞品:" rows="2" autosize type="textarea" maxlength="1024" placeholder="(选填)" />
						</van-form>
					</div>
				</div>
				<div style="width:100%;">
					<div style="width:100%;font-weight: bold;">3、设计需求简述</div>
					<div style="width:100%;">
						<div style="padding: 6px 0px ;">（可以结合风格、交互、配色、材料等，可附图标注）</div>
						<van-form >
							<van-field v-model="projectDesignQuestionnaire.designReqSummary" rows="4" autosize type="textarea" maxlength="1024" placeholder="(必填)" />
							<div style="width:100%;">附件(可上传多张图片)：</div>
							<van-field v-model="designReqAttachment" >
								<template #input>
									<van-uploader v-model="designReqAttachmentFile" />
								</template>
							</van-field>
						</van-form>
					</div>
				</div>
				<div style="width:100%;font-weight: bold;">
					<div style="width:100%;font-weight: bold;">4、设计资料：3D模型（stp格式）；企业logo（cdr、AI或PNG格式）；产品相关介绍资料，如画册、实物照片等参考(可附图)。如不能提供需备注</div>
					<div style="width:100%;">
						<van-form >
							<!-- <van-field v-model="designMaterials" rows="4" autosize type="textarea" maxlength="1024" placeholder="请输入" />
							<div style="width:100%;">3D模型（stp格式,支持上传多文件）：</div>
							<van-field v-model="stp3dModel" >
								<template #input>
									<van-uploader v-model="stp3dModelFile" accept=".stp" multiple />
								</template>
							</van-field>
							<div style="width:100%;">企业logo（cdr、AI或PNG格式）：</div>
							<van-field v-model="productDocs">
								<template #input>
									<van-uploader v-model="companyLogoFile" accept=".cdr, .ai, .png"/>
								</template>
							</van-field>
							<div style="width:100%;">产品相关介绍资料（如不能提供需备注）：</div>
							<van-field  >
								<template #input>
									<van-uploader v-model="productDocsFile" />
								</template>
							</van-field> -->
							<van-field v-model="projectDesignQuestionnaire.notes" label="备注:" rows="4" autosize type="textarea" maxlength="1024" placeholder="(选填)" >
							</van-field>
						</van-form>
					</div>
				</div>
			</div>
			<div style="width:100%;display: flex;justify-content: center;padding-bottom: 40px;">
				<!-- <div class="btn I-more"  @click="addProjectDesignQuestionnaire" style=""><span>提交</span></div> -->
				<van-button  @click="addProjectDesignQuestionnaire" type="primary" round style="width:100px;height: 31px;">提交</van-button>
			</div>
		</div>
		
    </div>
</template>

<script>

	export default {
		data() {
			return {
				customInfo:{},
				projectInfo:{},
				designReqAttachmentFile:[],
				stp3dModelFile:[],
				companyLogoFile:[],
				productDocsFile:[],
				projectDesignQuestionnaire:
				{
					"id": 1,
					"projectInfoId":0,
					"companyWebsite": "",
					"receiveEmail": "",
					"productSpec": "",
					"functionIntro": "",
					"usageEnv": "",
					"existingIssues": "",
					"competitors": "",
					"designReqSummary": "",
					"designReqAttachment": "",
					"designMaterials": "",
					"stp3dModel": "",
					"companyLogo": "",
					"productDocs": "",
					"notes": "",
					"createdAt": "",
					"updatedAt": ""
				}
				// {
				// 	"companyWebsite": "https://www.example.com",
				// 	"receiveEmail": "contact@example.com",
				// 	"productSpec": "产品规格描述内容",
				// 	"functionIntro": "功能简介内容",
				// 	"usageEnv": "使用环境描述内容",
				// 	"existingIssues": "现存问题描述内容",
				// 	"competitors": "竞品信息内容",
				// 	"designReqSummary": "设计需求简述内容",
				// 	"designReqAttachment": "/path/to/design_req_attachment.pdf",
				// 	"designMaterials": "/path/to/design_materials.zip",
				// 	"stp3dModel": "/path/to/3d_model.stp",
				// 	"companyLogo": "/path/to/logo.png",
				// 	"productDocs": "/path/to/product_docs.pdf",
				// 	"notes": "备注信息内容",
				// 	"createdAt": "2023-10-01T12:00:00.000+00:00",
				// 	"updatedAt": "2023-10-01T12:00:00.000+00:00"
				// }
			}
		},
		created() {
			this.projectDesignQuestionnaire.projectInfoId=this.$route.query.object_id*1;
			let _this=this;
			this.$yxxt_getCustomInfoByProjectInfoId({
				'projectInfoId':this.$route.query.object_id*1
			},function(data){
				_this.customInfo=data;
			},function(code,data){})
			this.$yxxt_getProjectInfoById({
				id:this.$route.query.object_id*1
			},function(data){
				_this.projectInfo=data;
			},function(code,data){});
		},
		mounted() {
		},
		methods: {
			addProjectDesignQuestionnaire(){
				let _this=this;
				let sum = 0;
				let ifFun=function(){	
					if(sum==4){
						_this.yxxt_addProjectDesignQuestionnaire();
					}
				}
				let designReqAttachmentFileLength = this.designReqAttachmentFile.length;
				if(designReqAttachmentFileLength>0){
					let designReqAttachmentFileIndex=0;
					for(let index in this.designReqAttachmentFile){
						let fileObj = this.designReqAttachmentFile[index];
						this.upload(fileObj,function(data){
							designReqAttachmentFileIndex++;
							if(designReqAttachmentFileLength == designReqAttachmentFileIndex){
								let path = '';
								for(let index1 in _this.designReqAttachmentFile){
									path += ","+_this.designReqAttachmentFile[index1].path;
								}
								sum++;
								_this.projectDesignQuestionnaire.designReqAttachment=path.substring(1);
								ifFun();
							}
						},function(code,data){});
					}
				}else{
					sum++;
					ifFun();
				}
				let stp3dModelFileLength = this.stp3dModelFile.length;
				if(stp3dModelFileLength>0){
					let stp3dModelFileIndex = 0;
					for(let index in this.stp3dModelFile){
						let obj = this.stp3dModelFile[index];
						this.upload(obj,function(data){
							stp3dModelFileIndex++;
							if(stp3dModelFileLength == stp3dModelFileIndex){
								let path = '';
								for(let index1 in _this.stp3dModelFile){
									path += ","+_this.stp3dModelFile[index1].path;
								}
								sum++;
								_this.projectDesignQuestionnaire.stp3dModel=path.substring(1);
								ifFun();
							}
						},function(code,data){});
					}
				}else{
					sum++;
					ifFun();
				}
				let companyLogoFileLength = this.companyLogoFile.length;
				if(companyLogoFileLength>0){
					let companyLogoFileIndex = 0;
					for(let index in this.companyLogoFile){
						let obj = this.companyLogoFile[index];
						this.upload(obj,function(data){
							companyLogoFileIndex++;
							if(companyLogoFileLength == companyLogoFileIndex){
								let path = '';
								for(let index1 in _this.companyLogoFile){
									path += ","+_this.companyLogoFile[index1].path;
								}
								sum++;
								_this.projectDesignQuestionnaire.companyLogo=path.substring(1);
								ifFun();
							}
						},function(code,data){});
					}
				}else{
					sum++;
					ifFun();
				}
				let productDocsFileLength = this.productDocsFile.length;
				if(productDocsFileLength>0){
					let productDocsFileIndex = 0;
					for(let index in this.productDocsFile){
						let obj = this.productDocsFile[index];
						this.upload(obj,function(data){
							productDocsFileIndex++;
							if(productDocsFileLength == productDocsFileIndex){
								let path = '';
								for(let index1 in _this.productDocsFile){
									path += ","+_this.productDocsFile[index1].path;
								}
								sum++;
								_this.projectDesignQuestionnaire.productDocs=path.substring(1);
								ifFun();
							}
						},function(code,data){});
					}
				}else{
					sum++;
					ifFun();
				}
			},
			upload(obj,backFun,errorFun){
				obj.status='uploading';
				this.$uploadOssFile(obj.file,function(data){
					obj.path=data;
					obj.status='done';
					backFun();
				},errorFun)
			},
			yxxt_addProjectDesignQuestionnaire(){
				let _this=this;
				this.$yxxt_addProjectDesignQuestionnaire(this.projectDesignQuestionnaire,function(data){
					console.log("上传成功");
					_this.$router.push({name:'dzqm',query:{id:data.id}})
					// _this.$router.push({name:'OK'})
				},function(code,data){});
			},
        }
	}
</script>

<style lang="less" scoped>
	
</style>
