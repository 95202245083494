<template>
  <div class="back_qm" style="width:100%; height:100%;">
    <!-- 签名区域 -->
    <canvas id="signature-container"></canvas>
    <div style="width:100%;height: 60px;">
    <!-- 保存按钮 -->
      <button @click="yxxt_updateProjectProductDesignOrder" id="save-button">提交</button>
      <button class="clear-button"  style="margin-left: 10px;">重签</button>
    </div>
    <!-- 生成的图片显示区域 -->
    <!-- <img id="generated-image" alt="生成的签名图片" /> -->
  </div>
</template>

<script>

  export default {
    props: {
      
    },
    data() {
      return {

      }
    },
    mounted(){
        // 获取 canvas 元素
      const canvas = document.getElementById('signature-container');
      const ctx = canvas.getContext('2d');
      // 设置 canvas 大小
      const setCanvasSize = () => {
        const bodyHeight = document.body.clientHeight; // 获取 body 的高度
        canvas.width = window.innerWidth ; // 宽度 100%
        canvas.height = window.innerHeight- 60;// bodyHeight; // 高度为 100% - 30px
      };
      // 初始化 canvas 大小
      setCanvasSize();
      // 锁定横屏
      const lockOrientation = () => {
        if (screen.orientation && screen.orientation.lock) {
          screen.orientation.lock('landscape').catch((error) => {
            console.error('锁定横屏失败:', error);
          });
        } else {
          console.warn('当前浏览器不支持锁定屏幕方向');
        }
      };
      // lockOrientation();
      // 监听窗口大小变化，调整 canvas 大小
      window.addEventListener('resize', setCanvasSize);

      // 绘制签名
      let isDrawing = false;

      canvas.addEventListener('touchstart', (e) => {
        isDrawing = true;
        const touch = e.touches[0];
        ctx.beginPath();
        ctx.moveTo(touch.clientX - canvas.offsetLeft, touch.clientY - canvas.offsetTop);
      });

      canvas.addEventListener('touchmove', (e) => {
        if (isDrawing) {
          const touch = e.touches[0];
          ctx.lineTo(touch.clientX - canvas.offsetLeft, touch.clientY - canvas.offsetTop);
          ctx.stroke();
        }
      });

      canvas.addEventListener('touchend', () => {
        isDrawing = false;
      });

      canvas.addEventListener('mousedown', (e) => {
        isDrawing = true;
        ctx.beginPath();
        ctx.moveTo(e.offsetX, e.offsetY);
      });

      canvas.addEventListener('mousemove', (e) => {
        if (isDrawing) {
          ctx.lineTo(e.offsetX, e.offsetY);
          ctx.stroke();
        }
      });

      canvas.addEventListener('mouseup', () => {
        isDrawing = false;
      });
      // 清空重签
      document.querySelector('.clear-button').addEventListener('click', () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height); // 清空 Canvas
        // generatedImage.src = ''; // 清空生成的图片
      });
      // // 生成图片并显示
      // const saveButton = document.getElementById('save-button');
      // // const generatedImage = document.getElementById('generated-image');

      // saveButton.addEventListener('click', () => {
      //   const imgData = canvas.toDataURL('image/png'); // 获取 Base64 数据
      //   console.log(imgData);
      //   window.open(imgData, "_blank");
      //   // generatedImage.src = imgData; // 将 Base64 数据赋值给 <img> 标签
      // });
    },
    components: {
     
    },
    methods: {
      yxxt_updateProjectProductDesignOrder(data){
        let _this=this;
        // 生成图片并显示
          const imgData = document.getElementById('signature-container').toDataURL('image/png'); // 获取 Base64 数据
        //上传文件
        this.$uploadBase64File({
          "base64":imgData.substring(22),
          "fileName":'Q'+new Date().getTime()+'.png'
        },function(data){
					console.log(data);
          _this.$yxxt_updateProjectDesignQuestionnaire({
            electronicSignature:data,
            id:_this.$route.query.id
          },function(data){
            console.log("上传成功");
            _this.$router.push({name:'OK'});
          },function(code,data){});
				},function(code,error){})
        // 
      },
    }
  }
</script>
<style>
  .back_qm { 
    background-image: url('../../../assets/5c845707a31044565b33538c59d9fbe.png'); /* 确保路径正确 */
    background-size: cover; /* 根据需要调整 */
    background-position: center; /* 根据需要调整 */
    }
  #signature-container {
      width: 100%; /* 宽度 100% */
      border: 1px solid #ccc;
      /* background-color: #fff; */
      touch-action: none; /* 防止触摸滚动 */
    }

    #save-button {
      /* margin-top: 10px; */
      width:60px;
      height:60px;
      font-size: 16px;
      background-color: #07c160;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transform: rotate(90deg);
    }
    .clear-button {
      /* margin-top: 10px; */
      width:60px;
      height:60px;
      font-size: 16px;
      background-color: #07c160;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transform: rotate(90deg);
    }
    

    #save-button:active {
      background-color: #06ad56;
    }

    #generated-image {
      margin-top: 20px;
      max-width: 100%;
      border: 1px solid #ccc;
      /* background-color: #fff; */
    }
</style>
