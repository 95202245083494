<template>
    <div style="width:100%;height:100%;">
		<div style="width:100%;height: 100%;">
			<van-field 
				readonly 
				label="公司名称:"
				v-model="customInfo.uname"
			/>
			<van-field 
				readonly 
				v-model="projectInfo.pname"
				label="产品名称:"
			/>
			<!-- 下单日期 -->
			<van-field
			v-model="form.orderDate"
			label="下单日期"
			placeholder="请选择下单日期"
			readonly
			@click="showDatePicker('orderDate')"
			/>

			<!-- 定稿完成日期 -->
			<van-field
			v-model="form.finalizationDate"
			label="定稿完成日期"
			placeholder="请选择定稿完成日期"
			readonly
			@click="showDatePicker('finalizationDate')"
			/>

			<!-- 提交方式 -->
			<van-field
				v-model="form.submissionMethod"
				label="提交方式"
				placeholder="请输入提交方式"
			/>
			<!-- 设计内容及要求 -->
			<van-field 
				v-model="form.designContent"
				label="设计内容及要求"
				placeholder="请输入设计内容及要求"
				rows="4" autosize type="textarea" maxlength="1024"
			/>
			<div style="width:100%;padding: 0px 20px;">
				<van-button type="primary" block @click="yxxt_insertProductDesignOrder">提交</van-button>
			</div>
			<van-popup v-if="showPicker" position="bottom">
				<div style="width:100%;height: 100%; background-color:rgba(0, 0, 0, 0.5); position:absolute; top: 0px;display: flex; align-items: flex-end;">
					<div style="width:100%;">
						<van-datetime-picker
							v-model="currentDate"
							type="date"
							@confirm="onConfirmDate"
							@cancel="showPicker = false"
						/>
					</div>
				</div>
			</van-popup>
		</div>
    </div>
</template>

<script>

	import dzqm from './dzqm_6.vue'
	export default {
		data() {
			return {	
				customInfo:{},
				projectInfo:{},
				currentDate:new Date(),
				form:{
				projectId: '',
				orderDate: '',
				finalizationDate: null,
				submissionMethod: '',
				designContent: '',
				},
				currentDateField:'',
				showPicker:false,
			}
		},
		components: {
			dzqm
    	},
		created() {
			this.form.projectInfoId=this.$route.query.object_id*1;
			let _this=this;
			this.$yxxt_getCustomInfoByProjectInfoId({
				'projectInfoId':this.$route.query.object_id*1
			},function(data){
				_this.customInfo=data;
			},function(code,data){})
			this.$yxxt_getProjectInfoById({
				id:this.$route.query.object_id*1
			},function(data){
				_this.projectInfo=data;
			},function(code,data){});
			
		},
		mounted() {
		},
		methods: {
			showDatePicker (field) {
				this.currentDateField = field;
				this.showPicker = true;
			},
			onConfirmDate (value) {
				const formattedDate = `${value.getFullYear()}-${String(value.getMonth() + 1).padStart(2, '0')}-${String(value.getDate()).padStart(2, '0')}`;
				this.form[this.currentDateField] = formattedDate;
				this.showPicker = false;
			},
			upload(obj,backFun,errorFun){
				obj.status='uploading';
				this.$uploadOssFile(obj.file,function(data){
					obj.path=data;
					obj.status='done';
					backFun();
				},errorFun)
			},
			yxxt_insertProductDesignOrder(){
				let _this=this;
				this.$yxxt_insertProductDesignOrder(this.form,function(data){
					console.log("上传成功");
					_this.$router.push({name:'dzqm',query:{object_id:data.id}})
				},function(code,data){});
			},
        }
	}
</script>

<style >
	/* .van-popup {width:100%;height: 100%;position: absolute; top: 0;} */
</style>
