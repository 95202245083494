
// utils/myFunctions.js
// export function getCaseType(backFun,error) {
//     this.$http.post_body_onexz('/case-type/getCaseType',{},backFun,error);
// }
export default {
    install(Vue, options) {
        Vue.prototype.$getCaseType = function(backFun,error) {
        // 你的功能实现
            this.$http.post_body_onexz('/case-type/getCaseType',{},backFun,error);
        };
        Vue.prototype.$getOnexzCaseListByTypePage = function(par,backFun,error) {
        // 你的功能实现
            this.$http.post_body_onexz('/case-type/getOnexzCaseListByTypePage',par,backFun,error);
        };

        Vue.prototype.$getOnexzNews = function(backFun,error) {
            this.$http.post_body_onexz('/onexz-news/getOnexzNews',{},backFun,error);
        };
    

    }
}