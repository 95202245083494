import { render, staticRenderFns } from "./project_product_design_order_info.vue?vue&type=template&id=a02320bc"
import script from "./project_product_design_order_info.vue?vue&type=script&lang=js"
export * from "./project_product_design_order_info.vue?vue&type=script&lang=js"
import style0 from "./project_product_design_order_info.vue?vue&type=style&index=0&id=a02320bc&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports