import Vue from 'vue'
import App from './App.vue'
import router from'./router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from './js/request'
import onexz from './js/onexz/onexz.js' //src\js\onexz\onexz.js
Vue.use(onexz);
import yxxt_onexz from './js/onexz/yxxt_onexz.js' //src\js\onexz\onexz.js
Vue.use(yxxt_onexz);
import tp from './js/onexz/tp.js' //src\js\onexz\onexz.js
Vue.use(tp);
// 2. 引入组件样式
import 'vant/lib/index.css';
import { Tab, Tabs } from 'vant';
Vue.use(Tab);
Vue.use(Tabs);
import { DatetimePicker } from 'vant';

Vue.use(DatetimePicker);
import { Button } from 'vant';
Vue.use(Button);
import { Field, CellGroup } from 'vant';
Vue.use(Field);
Vue.use(CellGroup);
import { Overlay } from 'vant';
Vue.use(Overlay);
import { Step, Steps } from 'vant';
Vue.use(Step);
Vue.use(Steps);
import { Uploader } from 'vant';
Vue.use(Uploader);
import { Toast } from 'vant';
Vue.use(Toast);
import { RadioGroup, Radio } from 'vant';
Vue.use(Radio);
Vue.use(RadioGroup);
import { Checkbox, CheckboxGroup } from 'vant';
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
import { Notify } from 'vant';
Vue.use(Notify);
import { Loading } from 'vant';
Vue.use(Loading);
import { Form } from 'vant';
Vue.use(Form);
import { NavBar } from 'vant';
Vue.use(NavBar);
import { Tabbar, TabbarItem } from 'vant';
Vue.use(Tabbar);
Vue.use(TabbarItem);
import { ContactCard } from 'vant';
Vue.use(ContactCard);
import { Image as VanImage } from 'vant';
Vue.use(VanImage);
import { Grid, GridItem } from 'vant';
Vue.use(Grid);
Vue.use(GridItem);
import { Icon } from 'vant';
Vue.use(Icon);
import { DropdownMenu, DropdownItem } from 'vant';
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
import { Search } from 'vant';
Vue.use(Search);
import { List } from 'vant';
Vue.use(List);
import { PullRefresh } from 'vant';
Vue.use(PullRefresh);
import { Cell } from 'vant';
Vue.use(Cell);
import { Popover } from 'vant';
Vue.use(Popover);
import { Collapse, CollapseItem } from 'vant';
Vue.use(Collapse);
Vue.use(CollapseItem);
import { Lazyload } from 'vant';
Vue.use(Lazyload);
import { Picker } from 'vant';
Vue.use(Picker);

import { Divider } from 'vant';
Vue.use(Divider);

import { Swipe, SwipeItem } from 'vant';
Vue.use(Swipe);
Vue.use(SwipeItem);
import { Col, Row } from 'vant';

Vue.use(Col);
Vue.use(Row);
import { TreeSelect } from 'vant';

Vue.use(TreeSelect);
// Vue.use(onexz); // 使用插件
// Vue.prototype.$http= axios
Vue.use(ElementUI);
Vue.config.productionTip = false
new Vue({
  router,
  axios,
  render: h => h(App)
}).$mount("#app");
// new Vue({
//   render: h => h(App),
//   router,
// }).$mount('#app')
