<template>
  <div style="width:100%;padding: 10px; background-color: #000;">
    <div style="width:100%;">
      <img class="logo_f" src="../../../assets/icons/logo_f.png"/>
      <van-tabs v-model="num" color="red" background="#000" @click="clickLi" title-active-color="#FFF">
        <van-tab title="广东" >
          <div style="width:100%;color:#FFF;font-size: 19px">  
            <div class="onexz_name">广东万喜至工业设计有限公司</div>
            <div style="width:100%;display: flex;padding: 4px 0px;">
              <div style="width:64px;">地址：</div>
              <div style="width: calc(100% - 64px);" >广东省东莞市松山湖区科技十路4号31栋904-908</div>
            </div>
            <div style="width:100%;display: flex;padding: 4px 0px;">
              <div style="width:64px;">Tel：</div>
              <div style="width: calc(100% - 64px);" ><div>0769-23224331</div> <div>13424706893(万总)</div></div>
            </div>
            <div style="width:100%;display: flex;padding: 4px 0px;align-items: center;">
              <div style="width:64px;">Fax：</div>
              <div style="width: calc(100% - 64px);" >0769-23027867</div>
            </div>
          </div>
        </van-tab>
        <van-tab title="江苏">
          <div style="width:100%;color:#FFF;font-size: 19px">  
            <div class="onexz_name">江苏万喜至工业设计有限公司</div>
            <div style="width:100%;display: flex;padding: 4px 0px;">
              <div style="width:64px;">地址：</div>
              <div style="width: calc(100% - 64px);" >江苏省海安市镇南路428号高新区科创园C222</div>
            </div>
            <div style="width:100%;display: flex;padding: 4px 0px;">
              <div style="width:64px;">Tel：</div>
              <div style="width: calc(100% - 64px);" >13424706893(万总)</div>
            </div>
            <div style="width:100%;display: flex;padding: 4px 0px;align-items: center;">
              <div style="width:64px;">Fax：</div>
              <div style="width: calc(100% - 64px);" >0769-23027867</div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <div style="width:100%;color:#FFF;font-size: 19px;margin-top: 20px;">
        <!-- <div class="tel_f"> -->
          
          <div style="width:100%;display: flex;margin-top: 12px;">
            <div style="width:50%;">
              <div style="width:100%; display: flex; justify-content: center;">
                <img :src="QR_code[0]" width="90px"/>
              </div>
              <div style="width:100%; font-size: 12px; text-align: center;padding: 8px;">微信公众号</div>
            </div>
            <div style="width:50%;">
              <div style="width:100%; display: flex; justify-content: center;">
                <img :src="QR_code[1]" width="90px"/>
              </div>
              <div style="width:100%; font-size: 12px; text-align: center;;padding: 8px;">客服微信</div>
            </div>
          </div>
          <div style="width:100%; margin-top: 14px;text-align: center;"> 
            <span>客服电话：</span><span style="">400-805-8511</span>
          </div>
          <div style="margin-top:30px;color: rgb(124, 124, 124);  font-size: 16px; text-align: center;">
            友情链接：<a href="http://www.zq-fa.com/zq-fa-web/" target="_blank" style="color: rgb(124, 124, 124); text-decoration: none; line-height: 30px;">众企优选</a>
          </div>
          <div style="margin-top:10px; font-size: 14px; color:#7c7c7c; text-align: center;">
              © Copyright 2019 广东万喜至工业设计有限公司&nbsp; 
              <a href="https://beian.miit.gov.cn/" target="_blank" style="text-decoration: none; font-size: 16px; line-height: 30px;">
                <div style="color:#7c7c7c;">粤ICP备2020073850号</div>
              </a>
          </div>
         
    </div>
  </div>

</template>

<script>

  export default {
    props: {
      
    },
    
    mounted() {
      window.addEventListener("scroll", this.btn_pos);
    },
    data() {
      return {            
        num:0,
        name:"广东万喜至工业设计有限公司",
        address:"广东省东莞市松山湖区科技十路4号31栋904-908",
        tel:"0769-23224331        13424706893（万总）  ",
        fax:"0769-23027867",
        QR_code:["http://www.zq-fa.com/oneXZ_official_website/index_footer/kf.jpg","http://www.zq-fa.com/oneXZ_official_website/index_footer/wx.jpg"]
      }
    },
    created(){
     
    },
    components: {
     
    },
    methods: {
      clickLi(index){      
        // this.num = index;
        if(num==1){
            this.name="广东万喜至工业设计有限公司";
            this.address="广东省东莞市松山湖区科技十路4号31栋904-908";
            this.tel="0769-23224331  13424706893  （万总）  ";
            this.fax="0769-23027867";     
        }
        else if(num==2){
          this.name="江苏万喜至工业设计有限公司";
            this.address="江苏省海安市镇南路428号高新区科创园C222";
            this.tel="13451689398  （万总）  ";
            this.fax="0769-23027867";   
        }
     },

    }
  }
</script>
<style> 


</style>
