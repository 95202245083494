<template>
  <div style="width:100%; height:100%;display: flex;justify-content: center;align-items: center;">
    <div class="success-container">
        <!-- 成功图标 -->
        <div class="success-icon">✓</div>
        <!-- 标题 -->
        <div class="success-title">提交成功！</div>
        <!-- 描述信息 -->
        <div class="success-message">
            您的信息已成功提交，我们会尽快与您联系。感谢您的信任！
        </div>
        <!-- 返回按钮 -->
        <!-- <button class="success-button" onclick="window.location.href='/'">返回首页</button> -->
    </div>
  </div>
</template>

<script>

  export default {
    props: {
      title:String,
      close_fun:{
        type: Function,
        required: true
      }
    },
    data() {
      return {
        
      }
    },
    created(){
     
    },
    components: {
     
    },
    methods: {
      
    }
  }
</script>
<style>
.success-container {
            text-align: center;
            background: white;
            padding: 40px;
            border-radius: 10px;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
            max-width: 400px;
            width: 100%;
        }

        .success-icon {
            font-size: 60px;
            color: #4caf50;
            margin-bottom: 20px;
        }

        .success-title {
            font-size: 24px;
            font-weight: bold;
            color: #333;
            margin-bottom: 10px;
        }

        .success-message {
            font-size: 16px;
            color: #666;
            margin-bottom: 20px;
        }

        .success-button {
            background-color: #4caf50;
            color: white;
            border: none;
            padding: 10px 20px;
            font-size: 16px;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s ease;
        }

        .success-button:hover {
            background-color: #45a049;
        }
</style>
