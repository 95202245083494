<template>
  <div class="" style="width:100%; position: absolute;z-index: 1024; bottom: 0; background-color: aqua;">
    <van-tabbar v-model="li_num" active-color="#e92232" @change="click_e">
      <van-tabbar-item  :name="0" icon="home-o">首页</van-tabbar-item>
      <van-tabbar-item  :name="1" icon="like-o">作品</van-tabbar-item>
      <van-tabbar-item  :name="2" icon="info-o">关于</van-tabbar-item>
      <van-tabbar-item  :name="3" icon="volume-o">新闻</van-tabbar-item>
      <van-tabbar-item  :name="4" icon="service-o">联系</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>

  export default {
    props: {
      
    },
    
    mounted() {
      window.addEventListener("scroll", this.btn_pos);
    
    },
    data() {
      return {   
        whFalseValue:false,
        show:0,
        li_num:0,
      }
    },
    created(){
      this.li_num =sessionStorage.li_num*1;
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // 移动设备的标识字符串
      const mobileRegex = /android|blackberry|iphone|ipad|ipod|iemobile|opera mini/i;
      if(!mobileRegex.test(userAgent)){//PC设备限定完度
        this.whFalseValue=true;
      }
    },
    components: {
     
    },
    methods: {
      btn_pos() {
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        if (scrollTop > 400) {
          this.show=1;        
        } else {
          this.show=0;         
        }      
      },
      click_e() {
        switch(this.li_num){
          case 0:
            this.$router.push({name:'phone_index'});
            break;
          case 1:
            window.location.href="https://mp.weixin.qq.com/mp/homepage?__biz=MzA4NzAxNzczMQ==&hid=1&sn=70d742af29630ed01b0451d68991fd61&scene=18";
            break;
          case 2:
            this.$router.push({name:'phone_about'});
            break;
          case 3:
            this.$router.push({name:'phone_news'});
            window.location.href="https://mp.weixin.qq.com/mp/homepage?__biz=MzA4NzAxNzczMQ==&hid=3&sn=467f9b9b5bcc836fccd9794bd704f6a9&scene=18#wechat_redirect";
            break;
          case 4:
            this.$router.push({name:'phone_contact'});
            break;
        }
      }
    }
  }
</script>
<style> 


</style>
