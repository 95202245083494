import Vue from 'vue'
import Axios from 'axios'
import qs from 'qs'
const axiosfile=Axios.create({

  baseURL:'http://www.zq-fa.com/third-party',
  // baseURL:'http://192.168.0.16:7003/file',
  // baseURL:'http://192.168.123.127:3000',
  //配置请求超时时间
  timeout: 5000
})
export function reqUploadImg(url,file) {
  return axiosfile({
    url: ''+url,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: file,
    params: { }
  })
}
Vue.prototype.$ufile=reqUploadImg;
                      
Vue.prototype.$axios = Axios;
// Vue.prototype.$url_onexz = 'http://192.168.0.23:7007/onexz-ow';
// Vue.prototype.$url_onexz = 'http://www.onexz.com/onexz-ow';
Vue.prototype.$url_onexz = 'http://www.onexz.com/onexz';
Vue.prototype.$urlFile = 'http://www.zq-fa.com/kwj_retirement_img';
// Vue.prototype.$urlFile = 'http://192.168.0.16:8081/kwj_retirement_img';
Vue.prototype.$urlFile2 = 'http://www.zq-fa.com/kwj_retirement_img';
Vue.prototype.$TP = 'http://www.zq-fa.com/third-party';
Vue.prototype.$TPView = 'http://www.zq-fa.com/third-party/TP-File-service/view?fileName=/';
// Vue.prototype.$urlFile = 'http://www.zq-fa.com/kwj_retirement_img';

// Vue.prototype.$url_onexz = 'http://192.168.0.23:80';




// Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// Axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
//Axios.defaults.withCredentials = true; //跨域
const axiosBody=Axios.create({
  // baseURL:Vue.prototype.$urlUpLoad+'/file',
  baseURL:'',
  //配置请求超时时间
  timeout: 15000
})
const axios_p=Axios.create({
  // baseURL:Vue.prototype.$urlUpLoad+'/file',
  baseURL:'',
  //配置请求超时时间
  timeout: 15000
})
export function reqAxiosBody(url,data) {
  return axiosBody({
    url: url,
    method: 'POST',
    headers: {
      'Content-Type':'application/json'
    },
    data: JSON.stringify(data),
    params: { }
  })
}
export function reqAxios(url,data) {
  console.log("AAURL:"+url);
  return axios_p({
    url: url,
    method: 'POST',
    headers: {
      'Content-Type':'application/json'
    },
    data: '',
    params: data
  })
}
Vue.prototype.$reqAxiosBody=reqAxiosBody;

// 添加请求拦截器
Axios.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  if(config.method == "post"){
    config.data = qs.stringify(config.data);
  }
  return config;
}, function (error) {
  // 对请求错误做些什么
  console.log("请求错误：");
  window.alert("请求服务器错误："+error);
  return Promise.reject(error);
});

// 添加响应拦截器
Axios.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  return response;
}, function (error) {
  // 对响应错误做点什么
  console.log("响应错误：");
  console.log(error);
  return Promise.reject(error);
});
// 假设有一个全局类
class MyGlobalClass {
  static uploadFile(url,file,ok_message,error1) {
    let formData = new FormData();
		formData.append('file', file); // 
    reqUploadImg(url,formData).then(res=>{
      console.log(res.data);
      let re = res.data;
      if(re.code == 200){
        ok_message(re.msg);
      }else{
        error1(re.code,re.msg);
      }
      }).catch(re=>{
          // console.log(err)
          // 回调出去
         
      })
  }
  static post_body_TP(url,par,ok_message,error1) {
    
    reqAxiosBody(Vue.prototype.$TP+url,par).then(res=>{
      let re = res.data;
      switch(re.code){
        case 200: 
          ok_message(re.msg);
          break;
        case 501:
          window.thisWindow.$message({
            showClose: true,
            message: re.msg,
            type: 'error'
          });
          break;
        default:
          error1(re.code,re.msg);
          break;
      }
    }).catch(re=>{
        // console.log(err)
        // 回调出去
    })
  }
  static post_body_onexz(url,par,ok_message,error1) {
    
    reqAxiosBody(Vue.prototype.$url_onexz+url,par).then(res=>{
      let re = res.data;
      // if(re.code == 200){
      //   ok_message(re.msg);
      // }else{
      //   error1(re.code,re.msg);
      // }
      switch(re.code){
        case 200: 
          ok_message(re.msg);
          break;
        case 501:
          window.thisWindow.$message({
            showClose: true,
            message: re.msg,
            type: 'error'
          });
          break;
        default:
          error1(re.code,re.msg);
          break;
      }
    }).catch(re=>{
        // console.log(err)
        // 回调出去
    })
  }
  static post_onexz(url,par,ok_message,error1) {
    console.log("url---:"+Vue.prototype.$url_onexz+url);
    reqAxios(Vue.prototype.$url_onexz+url,par).then(res=>{
      let re = res.data;
      switch(re.code){
        case 200: 
          ok_message(re.msg);
          break;
        case 501:
          window.thisWindow.$message({
            showClose: true,
            message: re.msg,
            type: 'error'
          });
          break;
        default:
          error1(re.code,re.msg);
          break;
      }
    }).catch(re=>{
        // console.log(err)
        // 回调出去
    })
  }
  


}
 // 在Vue的原型上添加这个全局类
Vue.prototype.$http =  MyGlobalClass;


export default class axios {
  
}
