import Vue from "vue";
import VueRouter from 'vue-router'
Vue.use(VueRouter);

import Add_Task_Sheet from '../components/pages/onexz/Add_Task_Sheet.vue'
import Task_Sheet_Info from '../components/pages/onexz/Task_Sheet_Info.vue'
import project_product_design_order from '../components/pages/onexz/project_product_design_order.vue'
import project_product_design_order_info from '../components/pages/onexz/project_product_design_order_info.vue'

import OK from '../components/pages/onexz/OK.vue'
import dzqm from '../components/pages/onexz/dzqm_6.vue'
import dzqm1 from '../components/pages/onexz/dzqm_1.vue'



import phone_index from '../components/pages/phone_index.vue'
import phone_about  from '../components/pages/component/phone_about.vue'
import phone_works  from '../components/pages/component/phone_works.vue'
import phone_news  from '../components/pages/component/phone_news.vue'
import phone_contact  from '../components/pages/component/phone_contact.vue'
import phone_onework  from '../components/pages/component/phone_onework.vue'
import index from '../components/pages/index.vue'



import onework  from '../components/pages/component/onework.vue'
import footer  from '../components/pages/component/footer.vue'
import works  from '../components/pages/component/works.vue'
import about  from '../components/pages/component/about.vue'
import contact  from '../components/pages/component/contact.vue'
import news  from '../components/pages/component/news.vue'


// 创建路由器
export default new VueRouter({
    // 可以配置多个路由
    routes : [
        {
            // 用于连接路由器的路径
            path : '/',
            name : '',
            component : index,
          
        },
        {
            // 用于连接路由器的路径
            path : '/phone_product_design_order_info',
            name : 'phone_product_design_order_info',
            component : project_product_design_order_info,
        },
        {
            // 用于连接路由器的路径
            path : '/product_design_order_info',
            name : 'product_design_order_info',
            component : project_product_design_order_info,
        },
        {
            // 用于连接路由器的路径
            path : '/Task_Sheet_Info',
            name : 'Task_Sheet_Info',
            component : Task_Sheet_Info,
        },
        {
            // 用于连接路由器的路径
            path : '/phone_Task_Sheet_Info',
            name : 'phone_Task_Sheet_Info',
            component : Task_Sheet_Info,
        },
        {
            // 用于连接路由器的路径
            path : '/phone_dzqm',
            name : 'phone_dzqm',
            component : dzqm,
        },
        {
            // 用于连接路由器的路径
            path : '/dzqm1',
            name : 'dzqm1',
            component : dzqm1,
        },
        {
            // 用于连接路由器的路径
            path : '/phone_dzqm1',
            name : 'phone_dzqm1',
            component : dzqm1,
        },
        {
            // 用于连接路由器的路径
            path : '/dzqm',
            name : 'dzqm',
            component : dzqm,
        },
        {
            // 用于连接路由器的路径
            path : '/phone_project_product_design_order',
            name : 'phone_project_product_design_order',
            component : project_product_design_order,
        },
        {
            // 用于连接路由器的路径
            path : '/project_product_design_order',
            name : 'project_product_design_order',
            component : project_product_design_order,
        },
        {
            // 用于连接路由器的路径
            path : '/OK',
            name : 'OK',
            component : OK,
        },
        {
            // 用于连接路由器的路径
            path : '/Add_Task_Sheet',
            name : 'Add_Task_Sheet',
            component : Add_Task_Sheet,
        },
        {
            // 用于连接路由器的路phone_Add_Task_Sheet径
            path : '/phone_Add_Task_Sheet',
            name : 'phone_Add_Task_Sheet',
            component : Add_Task_Sheet,
        },
        
        {
            // 用于连接路由器的路径
            path : '/phone_contact',
            name : 'phone_contact',
            component : phone_contact,
        },
        {
            // 用于连接路由器的路径
            path : '/phone_news',
            name : 'phone_news',
            component : phone_news,
        },
        {
            // 用于连接路由器的路径
            path : '/phone_works',
            name : 'phone_works',
            component : phone_works,
        },
        {
            // 用于连接路由器的路径
            path : '/phone_about',
            name : 'phone_about',
            component : phone_about,
        },
        {
            // 用于连接路由器的路径
            path : '/phone_index',
            name : 'phone_index',
            component : phone_index,
        },
        {
            // 用于连接路由器的路径
            path : '/phone_',
            name : 'phone_',
            component : phone_index,
        },
        {
            // 用于连接路由器的路径
            path : '/index',
            name : 'index',
            component : index,
           
        },
        {
            //尾部
            path : '/footer',
            name : 'footer',
            component : footer
        },
        {
            //单个案例界面
            path : '/phone_onework',
            name : 'phone_onework',
            component : phone_onework
        },
        {
            //单个案例界面
            path : '/onework',
            name : 'onework',
            component : onework
        },
        {
            //所有案例界面
            path : '/works',
            name : 'works',
            component : works
        },
        {
            //关于界面
            path : '/about',
            name : 'about',
            component : about
        },     
        {
            //联系界面
            path : '/contact',
            name : 'contact',
            component : contact
        },     
        {
            //新闻界面
            path : '/news',
            name : 'news',
            component : news
        },     
    ]
});
