
// utils/myFunctions.js
// export function getCaseType(backFun,error) {
//     this.$http.post_body_onexz('/case-type/getCaseType',{},backFun,error);
// }
export default {
    install(Vue, options) {
        Vue.prototype.$uploadOssFile = function(file,backFun,error) {
        // 你的功能实现
           this.$http.uploadFile('/TP-File-service/oss-upload',file,backFun,error);
        };
        Vue.prototype.$uploadBase64File = function(par,backFun,error) {
        // 你的功能实现
            this.$http.post_body_TP('/TP-File-service/ossUploadBase64',par,backFun,error);
        };

        

    }
}