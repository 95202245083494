<template>
  <div class="signature-container">
    <!-- 签名画板 -->
    <div ref="signaturePad" class="signature-pad" @touchstart="startDrawing" @touchmove="draw" @touchend="stopDrawing">
      <canvas ref="canvas" width="300" height="200"></canvas>
    </div>

    <!-- 清除按钮 -->
    <van-button type="danger" block @click="clearCanvas">清除签名</van-button>

    <!-- 确定按钮 -->
    <van-button type="primary" block @click="generateImage">确定生成图片</van-button>

    <!-- 生成的图片展示 -->
    <div v-if="imageUrl" class="image-preview">
      <img :src="imageUrl" alt="签名图片" />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import html2canvas from 'html2canvas';
import { Toast } from 'vant';

export default {
  setup() {
    const canvas = ref(null); // 画布元素
    const signaturePad = ref(null); // 签名区域元素
    const imageUrl = ref(''); // 生成的图片 URL
    let isDrawing = false; // 是否正在绘制
    let ctx = null; // 画布上下文

    // 初始化画布
    const initCanvas = () => {
      const canvasEl = canvas.value;
      ctx = canvasEl.getContext('2d');
      ctx.strokeStyle = '#000'; // 画笔颜色
      ctx.lineWidth = 2; // 画笔宽度
    };

    // 开始绘制
    const startDrawing = (event) => {
      isDrawing = true;
      const { offsetX, offsetY } = getTouchPosition(event);
      ctx.beginPath();
      ctx.moveTo(offsetX, offsetY);
    };

    // 绘制中
    const draw = (event) => {
      if (!isDrawing) return;
      const { offsetX, offsetY } = getTouchPosition(event);
      ctx.lineTo(offsetX, offsetY);
      ctx.stroke();
    };

    // 结束绘制
    const stopDrawing = () => {
      isDrawing = false;
      ctx.closePath();
    };

    // 获取触摸位置
    const getTouchPosition = (event) => {
      const rect = canvas.value.getBoundingClientRect();
      const touch = event.touches[0];
      return {
        offsetX: touch.clientX - rect.left,
        offsetY: touch.clientY - rect.top,
      };
    };

    // 清除画布
    const clearCanvas = () => {
      ctx.clearRect(0, 0, canvas.value.width, canvas.value.height);
      imageUrl.value = ''; // 清空生成的图片
    };

    // 生成图片
    const generateImage = () => {
      if (!ctx || !canvas.value) {
        Toast('请先签名');
        return;
      }

      // 使用 html2canvas 将画布转换为图片
      html2canvas(signaturePad.value).then((canvas) => {
        imageUrl.value = canvas.toDataURL('image/png'); // 生成图片 URL
        Toast('图片生成成功');
      });
    };

    // 初始化画布
    initCanvas();

    return {
      canvas,
      signaturePad,
      imageUrl,
      startDrawing,
      draw,
      stopDrawing,
      clearCanvas,
      generateImage,
    };
  },
};
</script>

<style scoped>
.signature-container {
  padding: 16px;
  text-align: center;
}

.signature-pad {
  border: 1px solid #ccc;
  margin-bottom: 16px;
  position: relative;
}

canvas {
  width: 100%;
  height: 200px;
  background-color: #f9f9f9;
}

.image-preview {
  margin-top: 16px;
}

.image-preview img {
  max-width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
}
</style>