
// utils/myFunctions.js
// export function getCaseType(backFun,error) {
//     this.$http.post_body_onexz('/case-type/getCaseType',{},backFun,error);
// }
export default {
    install(Vue, options) {
        Vue.prototype.$yxxt_getCustomInfoByProjectInfoId = function(par,backFun,error) {
            this.$http.post_onexz('/user/getCustomInfoByProjectInfoId',par,backFun,error);
        };
        Vue.prototype.$yxxt_getProjectInfoById = function(par,backFun,error) {
            this.$http.post_onexz('/project/getProjectInfoById',par,backFun,error);
        };
        Vue.prototype.$yxxt_getProjectDesignQuestionnaireByProjectInfoId = function(par,backFun,error) {
            this.$http.post_onexz('/project/getProjectDesignQuestionnaireByProjectInfoId',par,backFun,error);
        };
        
        Vue.prototype.$yxxt_addProjectDesignQuestionnaire = function(par,backFun,error) {
            this.$http.post_body_onexz('/project/addProjectDesignQuestionnaire',par,backFun,error);
        };
        Vue.prototype.$yxxt_insertProductDesignOrder = function(par,backFun,error) {
            this.$http.post_body_onexz('/project/insertProductDesignOrder',par,backFun,error);
        };
        Vue.prototype.$yxxt_updateProjectDesignQuestionnaire = function(par,backFun,error) {
            this.$http.post_onexz('/project/updateProjectDesignQuestionnaire',par,backFun,error);
        };
        Vue.prototype.$yxxt_getProjectProductDesignOrderByProjectInfoId = function(par,backFun,error) {
            this.$http.post_onexz('/project/getProjectProductDesignOrderByProjectInfoId',par,backFun,error);
        };

        
       

        

        
        

        

        
    }
}