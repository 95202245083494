<template>
  <div style="width:100%;height:100%; background-color: #fff">

      <van-swipe :autoplay="3000" indicator-color="red" >
        <van-swipe-item v-for="(image, index) in images" :key="index">
          <img :src="image.url"  width="100%" @click="goToContact(image)"/>
        </van-swipe-item>
      </van-swipe>
    <div >
      <o_head></o_head>
    </div>





       <!---->
    <div style="background-color: #f4f4f4; padding: 20px  5% 20px 5% ; float:left;">
       <div class="case">
        <div class="case_Big"   v-for="(image, index) in case_images" :key="index">
          <div class="case_list" >
            <div  class="case_pic">
              <img width="100%" style="float: left;" :src="image.url">
            </div>                
            <div class="case_title">
              <h1 class="h5_title" style="font-size:45px;">{{image.name}}</h1>
              <h2 class="h5_con">{{image.con}}</h2>
            </div>                
          </div>
        </div>           
      </div>
    </div>

    <div class="complaintPageContent">   
        <div class="reset ">
          <div class="index_pr1">
          <!-- <Transition  name="fade" > -->
            <div class="index_pr_left" v-if="fade"  >
              <div class="pr">
                <div class="tp" style="display: flex;  justify-content: space-between; align-items: center; "><img v-lazy="Works_Images[0].url" style="width: 100%; height: auto;" /></div>
                    <div class="wz">
                      <div class="div_name" @click="goToContact_work(Works_Images[0])"> 
                                       
                          <div  class="I_name"> {{Works_Images[0].title[0]}} </div>
                          <hr class="hr_"/>                       
                          <div  class="I_Ename">{{Works_Images[0].title[1]}}</div>                                             
                          
                      </div>
                  </div>
                </div>
            </div>
          <!-- </Transition> -->
            <div class="index_pr_Bigright">
              <div class="index_pr_right" data-wow-delay="0.3s" @click="fade=1">
                <div class="pr">
                  <div class="tp"><img v-lazy="Works_Images[1].url" style="width: 100%; height: auto;" /></div>
                    <div class="wz">
                      <div class="div_name"  @click="goToContact_work(Works_Images[1])">                         
                        <div  class="I_name"> {{Works_Images[1].title[0]}} </div>
                          <hr class="hr_"/>                       
                          <div  class="I_Ename">{{Works_Images[1].title[1]}}</div>        
                        </div>
                    </div>
                  </div>
              </div>

              <div class="index_pr_fadeIn" data-wow-delay="0.3s">
                <div class="pr">
                  <div class="tp"><img v-lazy="Works_Images[2].url" style="width: 100%; height: auto;" /></div>
                    <div class="wz">
                      <div class="div_name"  @click="goToContact_work(Works_Images[2])">                         
                          <div  class="I_name"> {{Works_Images[2].title[0]}} </div>
                          <hr class="hr_"/>                       
                          <div  class="I_Ename">{{Works_Images[2].title[1]}}</div>                               
                      </div>
                    </div>
                  </div>
              </div>
            </div>
        </div>


        <div class="reset ">
          <div class="index_pr1">           
            <div class="index_pr_Bigright2">
              <div class="index_pr_right" data-wow-delay="0.3s">
                <div class="pr">
                  <div class="tp"><img v-lazy="Works_Images[3].url" style="width: 100%; height: auto;" /></div>
                    <div class="wz">
                      <div class="div_name"  @click="goToContact_work(Works_Images[3])">                         
                          <div  class="I_name"> {{Works_Images[3].title[0]}} </div>
                          <hr class="hr_"/>                       
                          <div  class="I_Ename">{{Works_Images[3].title[1]}}</div>                               
                        </div>
                    </div>
                  </div>
              </div>

              <div class="index_pr_fadeIn" data-wow-delay="0.3s">
                <div class="pr">
                  <div class="tp"><img v-lazy="Works_Images[4].url" style="width: 100%; height: auto;" /></div>
                    <div class="wz">
                      <div class="div_name"  @click="goToContact_work(Works_Images[4])">                       
                          <div  class="I_name"> {{Works_Images[4].title[0]}} </div>
                          <hr class="hr_"/>                       
                          <div  class="I_Ename">{{Works_Images[4].title[1]}}</div>                          
                      </div>
                    </div>
                  </div>
              </div>
            </div>
            <div class="index_pr2_left" data-wow-delay="0.3s">
              <div class="pr">
                <div class="tp"><img v-lazy="Works_Images[5].url" style="width: 100%; height: auto;" /></div>
                    <div class="wz">
                      <div class="div_name"  @click="goToContact_work(Works_Images[5])">                      
                          <div  class="I_name"> {{Works_Images[5].title[0]}} </div>
                          <hr class="hr_"/>                       
                          <div  class="I_Ename">{{Works_Images[5].title[1]}}</div>                           
                      </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    <div style="float: left; width:100%; background-color: #fff; padding: 30px 0; ">
      <div class="btn I-more"  @click="click_e('works',1)"><span>更多案例</span><img  src="../../assets/icons/icon-go.png"/></div>
    </div>
  </div>

    <div  style="float:left;  padding: 0 5%; width:100%; text-align: center; margin-top:30px;">      
      <div style="font-size: 26px; width:100%; text-align: center;">
        <!-- <hr style="width:calc(45% - 100px); float:left;margin-top:30px; color:#DE303A"/> -->
        <strong style=" box-sizing: border-box; animation-fill-mode: both; padding: 0px; margin: 0px;  ">
          <span style="  font-size: 48px;    padding-bottom: 10px;  letter-spacing: 5px;">  我们的服务 </span>
          <br/>
         <span style="font-size: 16px;"> OUR SERVICES</span>
         </strong>
         <!-- <hr style="width:calc(45% - 100px); float:left; margin-top:30px; color:#DE303A"/> -->
      </div>

      <div style="margin-top:20px;">
        <div style="width:100%; display: flex;  justify-content: space-between; flex-wrap: wrap; ">
             <div class="latest_case_server"   v-for="(image, index) in servers_images" :key="index">
               <div class="latest_case_slist">
                  <div  class="latest_case_pic">
                    <img width="100%" style="float: left;" :src="image.url">
                  </div>                
                    <div class="latest_case_stitle">
                      <h2 class="h5_title">{{image.name}}</h2>
                      <!-- <h2 class="h5_title">{{image.Elish}}</h2> -->
                    </div>                
                </div>
              </div>            
            </div>
      </div>      
    </div>

    <!--动态新闻-->
    <div  style="float:left;  padding: 0 5%; width:100%; text-align: center; margin-top:30px;">     
      <div style="font-size: 26px; width:100%; text-align: center;">
        <!-- <hr style="width:calc(45% - 100px); float:left;margin-top:30px; color:#DE303A"/> -->
        <strong style=" box-sizing: border-box; animation-fill-mode: both; padding: 0px; margin: 0px;  ">
          <span style="  font-size: 48px;    padding-bottom: 10px;  letter-spacing: 5px;">  动态资讯 </span>
          <br/>
         <span style="font-size: 16px;"> NEWS</span>
         </strong>
         <!-- <hr style="width:calc(45% - 100px); float:left; margin-top:30px; color:#DE303A"/> -->
      </div>
      <div style="margin-top:20px;">
        <div style="width:100%; display: flex;  justify-content: space-between; flex-wrap: wrap; ">
             <div class="latest_case_news" @click="open_news(image.url)"   v-for="(image, index) in News_images" :key="index" v-if="index<3">
               <div class="latest_case_nlist">
                  <div  class="latest_case_npic">
                    <img width="100%" style="float: left;" :src="image.fmImg">
                  </div>                
                  <div class="latest_case_ntitle">
                      <div style="padding-bottom:10px;white-space: nowrap;   overflow: hidden;    text-overflow: ellipsis;">{{image.title}}</div>
                      <div style="float:left; font-size:14px;  color:#9c9c9c; vertical-align: middle;"><img src="../../assets/icons/date.png" width="20px" style="vertical-align: middle; margin-right:10px;"/><span>{{image.addTime.split(' ')[0]}}</span></div>
                      <!-- <div style="float:left; font-size:14px; color:#9c9c9c; margin-left: 30px; vertical-align: middle; margin-top: -5px;"><img src="../../assets/icons/look.png" width="30px"  style="vertical-align: middle"/> {{image.see_num}}</div> -->
                    </div>                
                </div>
              </div>            
            </div>
      </div>  
      <div style="float: left; width:100%; background-color: #fff; padding: 30px 0; ">
      <div class="btn I-more"  @click="click_e('news',3)"><span>更多资讯</span><img  src="../../assets/icons/icon-go.png"/></div>
    </div>    
  </div>

  <div>
    <o_footer></o_footer>
  </div>

<!-- 
  <div class="o_footer">
    <div class="foot_left">
       <img class="logo_f" src="../../assets/icons/logo_f.png"/>
       <ul class="address_li">
          <li @click="clickLi(1)" :class="num===1? 'li_c':'activetext' " >广东</li>
          <li @click="clickLi(2)" :class="num===2? 'li_c':'activetext' ">江苏</li>
       </ul>

       <div class="onexz_name">{{name}}</div>
       <div class="onexz_address">地址：{{address}}</div>   
       <div class="onexz_tel"> Tel：{{tel}}</div>   
       <div class="onexz_fax"> Fax：{{fax}}</div>   
    </div>   

    <div class="foot_right">

      <div class="tel_f">
        <p>客服电话</p>
        <p style="line-height:70px;">400-805-8511</p>
      </div>

      <div class="else_link">
        <a target="_blank">
          <img alt="" class="wx" src="../../assets/icons/wx2.png">        
        </a> 
        <a href="" target="_blank">
          <img alt="" src="../../assets/icons/fx31.png">
        </a> 
        <a href="" target="_blank">
          <img alt="" src="../../assets/icons/fx1.png">
        </a>
        <a href="" target="_blank">
          <img alt="" src="../../assets/icons/kz.png">
        </a>
      </div>     

      <div style="margin-top:30px;color: rgb(124, 124, 124);  font-size: 16px; ">
        友情链接：
        <a href="http://www.zq-fa.com/zq-fa-web/" target="_blank" style="color: rgb(124, 124, 124); text-decoration: none; line-height: 30px;">众企优选</a>
      </div>
      
      <p style="margin-top:10px; font-size: 14px; color:#7c7c7c;">
          © Copyright 2019 广东万喜至工业设计有限公司&nbsp; 
          <a href="https://beian.miit.gov.cn/" target="_blank" style="text-decoration: none; font-size: 16px; line-height: 30px;">
            <div style="color:#7c7c7c;">粤ICP备2020073850号</div>
          </a>
      </p>
    </div>

  </div> -->

  </div>
</template>

<script>

import { Lazyload } from 'vant';

import Vue from 'vue'
import VueLazyload from 'vue-lazyload'
 
Vue.use(VueLazyload, {
  preLoad: 1,
  error: '', // 占位图
  loading: 'http://www.zq-fa.com/oneXZ_official_website/loading.jpg', // 占位图
  attempt: 1 // 尝试加载的次数
})

import o_footer  from '../../components/pages/component/footer.vue'
import o_head  from '../../components/pages/component/onexz_head.vue'
export default {
  name: 'onexz',
  components: {
    o_footer,o_head
  },
  props: {
    
  },
  mounted() {
    document.title = '广东万喜至工业设计有限公司-工业设计-机械外观设计'; // 设置页面标题
    window.addEventListener("scroll", this.btn_pos);
    window.scrollTo(0, 0);
  },

  data() {
    return {
      fade:1,
      bannerheight:"",
      // images: ['http://www.zq-fa.com/oneXZ_official_website/banner_imgs/1.jpg',
      //   'http://www.zq-fa.com/oneXZ_official_website/banner_imgs/2.jpg','http://www.zq-fa.com/oneXZ_official_website/banner_imgs/3.jpg','http://www.zq-fa.com/oneXZ_official_website/banner_imgs/4.jpg','http://www.zq-fa.com/oneXZ_official_website/banner_imgs/5.jpg'],
      images:[{"url":"http://www.zq-fa.com/oneXZ_official_website/banner_imgs/1.jpg","name":["数控外圆磨床",["本款精密数控外圆磨床的设计灵感源自对现代工业制造精度与效率的极致追求。","采用高品质材料和精密制造工艺，旨在为用户带来一款集高效、精准、稳定于一体的工业级磨削解决方案","长久的耐用性，让您的投资更具价值。"],"服务内容：产品设计｜结构设计｜视觉设计｜动画功能"],"infoImgs":['http://www.zq-fa.com/oneXZ_official_website/one_work/b1/info1.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b1/info2.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b1/info3.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b1/info4.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b1/info5.jpg'],"moreImgs":[]},
      {"url":"http://www.zq-fa.com/oneXZ_official_website/banner_imgs/2.jpg","name":["龙门加工中心",["融合尖端科技与工业美学的精髓，为现代制造业提供一款高效、精准、灵活的加工解决方案。","结合最新的数控技术与精密机械工艺，打造出这款集先进性、实用性、美观性于一身的龙门加工中心。"],"服务内容：产品设计｜结构设计｜视觉设计｜动画功能"],"infoImgs":['http://www.zq-fa.com/oneXZ_official_website/one_work/b2/info1.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b2/info2.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b2/info3.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b2/info4.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b2/info5.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b2/info6.jpg'],"moreImgs":[]},
      {"url":"http://www.zq-fa.com/oneXZ_official_website/banner_imgs/3.jpg","name":["高速加工中心",["具备高速主轴和强大的切削能力，能够实现对材料的快速、精准加工，为用户提供高效、稳定的加工解决方案。","以“高效、精准”为核心设计理念，我们深度融合了最前沿的数控技术和精密机械设计，","确保每一台设备都能成为用户提升生产效率、优化加工品质的重要伙伴。"],"服务内容：产品设计｜结构设计｜视觉设计｜动画功能"],"infoImgs":['http://www.zq-fa.com/oneXZ_official_website/one_work/b3/info1.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b3/info2.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b3/info3.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b3/info4.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b3/info5.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b3/info6.jpg'],"moreImgs":[]},
      {"url":"http://www.zq-fa.com/oneXZ_official_website/banner_imgs/4.jpg","name":["数码标签机",["为现代工业及商业环境设计的高精度、高效率自动化打印设备。","它融合了先进的数字打印技术、智能化操作界面与坚固耐用的机械结构，旨在为用户提供一种便捷、精准、灵活的标签解决方案。","通过本产品的应用，企业能够显著提升生产效率，增强品牌形象。"],"服务内容：产品设计｜结构设计｜视觉设计｜动画功能"],"infoImgs":['http://www.zq-fa.com/oneXZ_official_website/one_work/b4/info1.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b4/info2.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b4/info3.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b4/info4.jpg'],"moreImgs":[]},
      {"url":"http://www.zq-fa.com/oneXZ_official_website/banner_imgs/5.jpg","name":["塑料造粒机",["此设备采用直线式子母组合结构，智能化的技术使生产效率更高；","一体化的结构造型更加简洁；上翻加滑轨门的设计，在安全防护的同时还能切换更大的维修操作空间；","底座向内倾斜，有效提升人机交互体验。通过全新的技术和理念支持塑料行业，为可持续加工做贡献。"],"服务内容：产品设计｜结构设计｜视觉设计｜动画功能"],"infoImgs":['http://www.zq-fa.com/oneXZ_official_website/one_work/b5/info1.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b5/info2.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b5/info3.jpg'],"moreImgs":[]},
    ],
    // Works_Images:[{"url":"http://www.zq-fa.com/oneXZ_official_website/index_work/w1.jpg","title":["食品包装机","PRODUCT DESIGN"],"name":["食品包装机",["本款精密数控外圆磨床的设计灵感源自对现代工业制造精度与效率的极致追求。","采用高品质材料和精密制造工艺，旨在为用户带来一款集高效、精准、稳定于一体的工业级磨削解决方案","长久的耐用性，让您的投资更具价值。"],"服务内容：产品设计｜结构设计｜视觉设计｜动画功能"],"infoImgs":['http://www.zq-fa.com/oneXZ_official_website/one_work/w1/info1.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w1/info2.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w1/info3.jpg'],"moreImgs":[]},
    //     {"url":"http://www.zq-fa.com/oneXZ_official_website/index_work/w2.jpg","title":["药品智能管理工作站","PRODUCT DESIGN"],"name":["药品智能管理工作站",["融合尖端科技与工业美学的精髓，为现代制造业提供一款高效、精准、灵活的加工解决方案。","结合最新的数控技术与精密机械工艺，打造出这款集先进性、实用性、美观性于一身的龙门加工中心。"],"服务内容：产品设计｜结构设计｜视觉设计｜动画功能"],"infoImgs":['http://www.zq-fa.com/oneXZ_official_website/one_work/w2/info1.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w2/info2.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w2/info3.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w2/info4.jpg'],"moreImgs":[]},
    //     {"url":"http://www.zq-fa.com/oneXZ_official_website/index_work/w3.jpg","title":["贝尔_造粒机","PRODUCT DESIGN"],"name":["贝尔_造粒机",["具备高速主轴和强大的切削能力，能够实现对材料的快速、精准加工，为用户提供高效、稳定的加工解决方案。","以“高效、精准”为核心设计理念，我们深度融合了最前沿的数控技术和精密机械设计，","确保每一台设备都能成为用户提升生产效率、优化加工品质的重要伙伴。"],"服务内容：产品设计｜结构设计｜视觉设计｜动画功能"],"infoImgs":['http://www.zq-fa.com/oneXZ_official_website/one_work/w3/info1.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w3/info2.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w3/info3.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w3/info4.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w3/info5.jpg'],"moreImgs":[]},
    //     {"url":"http://www.zq-fa.com/oneXZ_official_website/index_work/w4.jpg","title":["全自动测试机","PRODUCT DESIGN"],"name":["全自动测试机",["为现代工业及商业环境设计的高精度、高效率自动化打印设备。","它融合了先进的数字打印技术、智能化操作界面与坚固耐用的机械结构，旨在为用户提供一种便捷、精准、灵活的标签解决方案。","通过本产品的应用，企业能够显著提升生产效率，增强品牌形象。"],"服务内容：产品设计｜结构设计｜视觉设计｜动画功能"],"infoImgs":['http://www.zq-fa.com/oneXZ_official_website/one_work/w4/info1.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w4/info2.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w4/info3.jpg'],"moreImgs":[]},
    //     {"url":"http://www.zq-fa.com/oneXZ_official_website/index_work/w5.jpg","title":["修板机","PRODUCT DESIGN"],"name":["修板机",["此设备采用直线式子母组合结构，智能化的技术使生产效率更高；","一体化的结构造型更加简洁；上翻加滑轨门的设计，在安全防护的同时还能切换更大的维修操作空间；","底座向内倾斜，有效提升人机交互体验。通过全新的技术和理念支持塑料行业，为可持续加工做贡献。"],"服务内容：产品设计｜结构设计｜视觉设计｜动画功能"],"infoImgs":['http://www.zq-fa.com/oneXZ_official_website/one_work/w5/info1.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w5/info2.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w5/info3.jpg'],"moreImgs":[]},
    //     {"url":"http://www.zq-fa.com/oneXZ_official_website/index_work/w6.jpg","title":["高速飞针测试机","PRODUCT DESIGN"],"name":["高速飞针测试机",["融合尖端科技与工业美学的精髓，为现代制造业提供一款高效、精准、灵活的加工解决方案。","结合最新的数控技术与精密机械工艺，打造出这款集先进性、实用性、美观性于一身的龙门加工中心。"],"服务内容：产品设计｜结构设计｜视觉设计｜动画功能"],"infoImgs":['http://www.zq-fa.com/oneXZ_official_website/one_work/w6/info1.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w6/info2.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w6/info3.jpg'],"moreImgs":[]},
    //    ],      

    Works_Images:[{"url":"http://www.zq-fa.com/oneXZ_official_website/index_work/w1.jpg","title":["食品包装机","PRODUCT DESIGN"],"name":["食品包装机",["这款食品包装机集高效、智能、安全于一身，专为满足现代食品行业对高质量、高效率包装解决方案的需求而精心打造。","该机器以白色和灰色为主色调，搭配橙色和黑色的精致装饰线条，不仅展现出其现代化的设计风格，更彰显了产品的高端与稳重。"],"服务内容：产品设计｜结构设计｜视觉设计｜动画功能"],"infoImgs":['http://www.zq-fa.com/oneXZ_official_website/one_work/w1/info1.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w1/info2.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w1/info3.jpg'],"moreImgs":[]},
        {"url":"http://www.zq-fa.com/oneXZ_official_website/index_work/w2.jpg","title":["药品智能管理工作站","PRODUCT DESIGN"],"name":["药品智能管理工作站",["本款药品智能管理工作站采用简洁而现代的灰白双色设计，不仅美观大方，更易于融入各种商业与医疗环境。","其整体设计注重人机工程学原理，确保操作人员能够轻松访问与管理各类药品。","每个工作站均配备高清触摸屏显示屏，实时显示药品信息、库存量及交易记录，使管理更加直观便捷。"],"服务内容：产品设计｜结构设计｜视觉设计｜动画功能"],"infoImgs":['http://www.zq-fa.com/oneXZ_official_website/one_work/w2/info1.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w2/info2.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w2/info3.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w2/info4.jpg'],"moreImgs":[]},
        {"url":"http://www.zq-fa.com/oneXZ_official_website/index_work/w3.jpg","title":["造粒机","PRODUCT DESIGN"],"name":["造粒机",["整机以白色和灰色为主要配色，白色给人以干净纯洁的感觉，灰色给人以沉稳大气的感觉。","整机配色简洁大方，符合现代工业审美。"],"服务内容：产品设计｜结构设计｜视觉设计｜动画功能"],"infoImgs":['http://www.zq-fa.com/oneXZ_official_website/one_work/w3/info1.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w3/info2.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w3/info3.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w3/info4.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w3/info5.jpg'],"moreImgs":[]},
        {"url":"http://www.zq-fa.com/oneXZ_official_website/index_work/w4.jpg","title":["全自动测试机","PRODUCT DESIGN"],"name":["全自动测试机",["这款全自动测试机使用灰色作为主色调，不仅显得稳重、专业，还能够很好地融入各种工业环境，展现出设备的耐用性和高品质。","上方的显示屏和操作按钮布局合理，既便于操作人员观察和操作，又显得美观大方。"],"服务内容：产品设计｜结构设计｜视觉设计｜动画功能"],"infoImgs":['http://www.zq-fa.com/oneXZ_official_website/one_work/w4/info1.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w4/info2.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w4/info3.jpg'],"moreImgs":[]},
        {"url":"http://www.zq-fa.com/oneXZ_official_website/index_work/w5.jpg","title":["修板机","PRODUCT DESIGN"],"name":["修板机",["此产品使用立式结构，整体线条流畅。采用白色作为主色调，给人以干净清爽的感觉。","少量的绿色中和白色和灰色的沉闷感，使整机看起来更加活泼，外观设计新颖独特，具有很强的视觉冲击力。"],"服务内容：产品设计｜结构设计｜视觉设计｜动画功能"],"infoImgs":['http://www.zq-fa.com/oneXZ_official_website/one_work/w5/info1.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w5/info2.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w5/info3.jpg'],"moreImgs":[]},
        {"url":"http://www.zq-fa.com/oneXZ_official_website/index_work/w6.jpg","title":["高速飞针测试机","PRODUCT DESIGN"],"name":["高速飞针测试机",["这款设备集成了先进的技术与精湛的工艺，专为精密机械加工及高端电子元件组装领域量身打造。","它不仅能够大幅提升生产效率，还能确保产品质量的卓越与稳定，是追求高效与优质并重的理想选择。","独特的设计理念和先进的技术应用，使得设备在精密机械加工和高端电子元件组装领域具有显著的优势，能够满足对高精度、高效率生产线的严苛要求。"],"服务内容：产品设计｜结构设计｜视觉设计｜功能动画"],"infoImgs":['http://www.zq-fa.com/oneXZ_official_website/one_work/w6/info1.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w6/info2.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w6/info3.jpg'],"moreImgs":[]},
       ],      

      servers_images: [{"url":"http://www.zq-fa.com/oneXZ_official_website/index_server/品牌规划.jpg","name":"品牌规划","Elish":"Brand plannineg"},
      {"url":"http://www.zq-fa.com/oneXZ_official_website/index_server/2.jfif","name":"外观设计","Elish":"Industrial Design"},
      {"url":"http://www.zq-fa.com/oneXZ_official_website/index_server/1.jfif","name":"结构设计","Elish":"Mechanism Design"},
      {"url":"http://www.zq-fa.com/oneXZ_official_website/index_server/d.jpg","name":"钣金制造","Elish":"Metal plate samples"},
      {"url":"http://www.zq-fa.com/oneXZ_official_website/index_server/a.jpg","name":"模型制作","Elish":"Model makin"},
      {"url":"http://www.zq-fa.com/oneXZ_official_website/index_server/b.jpg","name":"功能动画","Elish":"Functional Animations"},
      {"url":"http://www.zq-fa.com/oneXZ_official_website/index_server/c.jpg","name":"研发与供应链","Elish":"Supply chain"},
      {"url":"http://www.zq-fa.com/oneXZ_official_website/index_server/品牌规划.jpg","name":"设备UI设计","Elish":"UI"}
    ],
      
    //  News_images: [{"url":"http://www.zq-fa.com/oneXZ_official_website/news/new3.jfif","name":"产教对接·互促共赢 —工业设计高层次人才交流会暨校企合作签约仪式","date":"2023-12-25","see_num":"10","open_url":"https://mp.weixin.qq.com/s/4poMIV9vs7Fbg4ZjgmCgZw"},    
    //   {"url":"http://www.zq-fa.com/oneXZ_official_website/news/new2.jfif","name":"从概念到落地的创新设计研究","date":"2023-12-20","see_num":"20","open_url":"https://mp.weixin.qq.com/s/BGFcjn3YzlfDwj5QwAwM8w"},
    //   {"url":"http://www.zq-fa.com/oneXZ_official_website/news/new1.jfif","name":"万喜至再次获得荣誉 |2023东莞杯国际工业设计大赛","date":"2023-12-10","see_num":"96","open_url":"https://mp.weixin.qq.com/s/pxHhE7S9yZybOPh08lNxFQ"},
      
    // ],
    News_images: [],
    case_images:[{"url":"http://www.zq-fa.com/oneXZ_official_website/index_server/2.jfif","name":"18年","con":"工业设计实战经验"},
    {"url":"http://www.zq-fa.com/oneXZ_official_website/one_work/w2/info1.jpg","name":"500+","con":"实用新型专利"},
    {"url":"http://www.zq-fa.com/oneXZ_official_website/index_server/2.jfif","name":"1000+","con":"国内外知名设计奖项"},
    {"url":"http://www.zq-fa.com/oneXZ_official_website/news/new3.jfif","name":"2000+","con":"全国知名企业合作伙伴"},
    {"url":"http://www.zq-fa.com/oneXZ_official_website/one_work/w3/info1.jpg","name":"10000+","con":"创新产品成功上市"}],

    //18年工业设计实战经验-500+实用新型专利-1000+国内外知名设计奖项-2000+全国知名企业合作伙伴-10000+创新产品成功上市

      scrollTop: 0,
      show:0,
    
    }
  },
  methods: {
    News_images(){

    },
    btn_pos() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 400) {
        this.show=1;        
      } else {
        this.show=0;         
      }      
    },

    goToContact(imgs) {
      // 编程式导航到 / 路径   
      localStorage.setItem("onework_info",JSON.stringify(imgs));
      this.$router.push({name:'onework'});
    },

    
    goToContact_work(imgs) {
      // 编程式导航到 / 路径   
      localStorage.setItem("onework_info",JSON.stringify(imgs));
      this.$router.push({name:'onework'});
    },
    open_news(url){
      window.open(url,'_blank');
    },
    click_e(url,num) {
      if(num != this.li_num){
        // 编程式导航到 / 路径   
        this.$router.push({name:url});
      }
    } 
  },
  created(){
    let _this=this;
    sessionStorage.li_num = 0;
    this.$getOnexzNews(function(data){
      _this.News_images=data.result;
    },function(code,error){})
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  body{font-family: 微软雅黑, arial;}

  .heard { width: 100%;  padding-top:30px; padding-bottom: 30px;  position: fixed; top: 0; z-index: 1000;  padding-left: 5%;}
  .logo { float: left; height: 30px; }
  .heard  ul li{ float: left; padding: 6px 20px 6px 20px;list-style: none}
  .heard  ul li  {color: #000; font-size:17px; text-shadow: 0px 0px 2px #000000; cursor: pointer;}
  .heard_ul li:hover{ color:#DE303A;  text-shadow: 0px 0px 2px #DE303A;}
  .top_show{opacity: 0; top:-500px; display: none;}
  .top_hide{ opacity: 1; top:0px; display: block;}

  .checked_li{ color:#fff !important; border-radius: 16px; text-shadow: none !important; }
  .No_checked_li{ background-color: none; color:#000;}
  .ch{ display: none}
  .swiper-slide{ width:100%; height: auto;}
  .banner_box{ width:100%; }
  .el-carousel__item:nth-child(2n) {
     background-color: #99a9bf;
  }
  .el-carousel__item:nth-child(2n+1) {
     background-color: #d3dce6;
  }
  .complaintPageContent{padding:0 5%; float:left; margin-top:20px;}
 .el-carousel__button{
      width: 13px !important;
      height: 13px !important;  
      border-radius:100%;  
 }
 .el-carousel__indicator.is-active  .el-carousel__button{
   background-color: #E31639 !important; 
 }
 .van-swipe__indicator{
  width: 13px !important;
  height: 13px !important;  
 }
 /*轮播图底下部分 */  
 .case{  float: left; display: flex; justify-content: space-between;  }     
.case_Big{width:19%; border-radius: 8px; float:left; height: auto; position: relative; overflow:hidden;}
.case_list{ width:100%; height: auto; float:left;overflow:hidden; }
.case_pic{width: 100%; height: auto; float:left; overflow:hidden; }
.case_Big .case_title{ opacity:1;}
.case_Big:hover .h5_title{ animation: slide-up2 0.5s ease forwards; /* 动画名称，时长，缓动函数，填充模式 */}
.case_Big:hover img{ overflow:hidden; transform: scale(1.1); transition: all 0.5s;}
.case_title{position: absolute; top:0; left:0; width:100%; height: 100%;
        background-color: rgba(0,0,0,0.2);
        color:#fff;
        padding-top: calc((100% - 53px) / 5);
        text-align: center;
        justify-content: center; /* 水平居中 */
        align-items: center; /* 垂直居中 */
        opacity: 0; /*初始时隐藏文本 */
       /* transition: opacity 0.3s ease-in-out; /* 平滑过渡效果 */
         transition: transform 0.5s linear;
      }
  /* 定义动画 */
  @keyframes slide-up2 {
      from {
        transform: translateY(10%); /* 从下边开始 */
        opacity: 0; /* 不透明度为0 */
      }
      to {
        transform: translateY(0); /* 移动到原位置 */
        opacity: 1; /* 完全不透明 */
      }
    }
    




  .index_pr1{ width:100%;  height: 100%; position: relative;float: left; border-top:6px solid #fff;}


  .index_pr_left{ float: left; width:50%; box-sizing: border-box; border-top: 0px; border-right:6px; border-bottom: 0px; border-left:0; border-color: #fff; border-style: solid; height: 100%;  position: relative;  }
  .index_pr_Bigright{  float: left; width:50%; height: 100%; box-sizing: border-box;  position: relative; border:0;}

  .index_pr_right{ float: left; width:100%; box-sizing: border-box;  border-top: 0px; border-right:0; border-bottom: 0; border-left:0px;  border-color: #fff; border-style: solid; height: 50%;  position: relative;  }
  .index_pr_fadeIn{ float: left;  width:100%; box-sizing: border-box;  border-top: 6px; border-right:0; border-bottom: 0; border-left:0px; border-color: #fff; border-style: solid; height: 50%;   position: relative;}
  .div_name{width:100%;  text-align: center; cursor:pointer}
  .wz{  position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3); /* 半透明背景 */
        color: #fff; /* 白色文字 */
        display: flex;
        justify-content: center; /* 水平居中 */
        align-items: center; /* 垂直居中 */
         opacity: 0; /*初始时隐藏文本 */
       /* transition: opacity 0.3s ease-in-out; /* 平滑过渡效果 */
         transition: transform 0.3s linear;
}
  .hr_{width:200px; height: 1px; background-color: #fff; margin:0 auto; }
  .wz a{ color:#fff; }
  .wz .I_name{font-size: 30px; font-weight: bold; height: 57px;}
  .wz .I_Ename{font-size: 20px;  height: 55px; margin-top:17px;}
  .pr:hover .wz{ opacity:1;}
  .pr:hover  img{ overflow:hidden; transform: scale(1.1);    transition: all 0.5s;}
  .tp img{ overflow: hidden;  transition:  0.3s; float: left;}
  .tp {width:100%; height:100%; overflow: hidden; box-sizing: border-box; float: left;}
  .pr {width:100%; height:100%; overflow: hidden; float: left;}



  .index_pr2_left{float: left;  box-sizing: border-box; width:50%; border-top:0px; border-left:0px; border-bottom: 0px; border-right:0; border-color: #fff; border-style: solid; height: 100%;  position: relative;  }
  .index_pr_Bigright2{  float: left;  box-sizing: border-box; width:50%; height: 100%;  position: relative; border:0; border-right:6px; border-color: #fff; border-style: solid;}


  .I-more{
    cursor:pointer;    
     margin:0 auto;
    width: 232px;
    line-height: 44px;
    font-size: 16px;
    color: #000000;
    border-style: solid;
    border-width: 2px;
    text-align: center;
   
}
.I-more img{ margin-left: 15px; vertical-align: middle;}



    /* 定义动画 */
    @keyframes slide-up {
      from {
        transform: translateY(50%); /* 从下边开始 */
        opacity: 0; /* 不透明度为0 */
      }
      to {
        transform: translateY(0); /* 移动到原位置 */
        opacity: 1; /* 完全不透明 */
      }
    }
    
    /* 应用动画*/
    .pr:hover  .div_name{
      animation: slide-up 0.3s ease forwards; /* 动画名称，时长，缓动函数，填充模式 */
  }



/*我们的服务css */
.latest_case_server{  margin:15px 0; width:23%; height:200px; background-color: rgb(233, 226, 226);  border:1px solid #ddd; border-radius: 12px;  position: relative; overflow:hidden;}
.latest_case_slist{ width:100%; height: auto; float:left;overflow:hidden; }
.latest_case_spic{width: 100%; height: auto; float:left; overflow:hidden; }
   
.latest_case_stitle{position: absolute; bottom:0; left:0; width:100%; height: 50px;
        background-color: rgba(0, 0, 0, 0.4);
        /* backdrop-filter: blur(45px); */
        color:#fff;
        display: flex;
        justify-content: center; /* 水平居中 */
        align-items: center; /* 垂直居中 */      
       /* transition: opacity 0.3s ease-in-out; /* 平滑过渡效果 */
         transition: transform 0.5s linear;
      }
/* .latest_case_server:hover .h5_title{ animation: slide-up2 0.5s ease forwards; /* 动画名称，时长，缓动函数，填充模式 } */
.latest_case_server:hover img{ overflow:hidden; transform: scale(1.3);    transition: all 0.5s;}



/*动态资讯css */
.latest_case_news{ cursor: pointer; margin:15px 0; width:32%; height:350px; background-color: rgb(233, 226, 226);  border:1px solid #ddd; border-radius: 15px;  position: relative; overflow:hidden;}
.latest_case_nlist{ width:100%; height: auto; float:left;overflow:hidden; }
.latest_case_npic{width: 100%; height: auto; float:left; overflow:hidden; }
   
.latest_case_ntitle{position: absolute; bottom:0; left:0; width:100%; height:70px;
        background-color: rgb(250, 250, 250);
        backdrop-filter: blur(45px);
        color:#000;
         padding: 10px 22px;
        font-size: 18px;
       /* transition: opacity 0.3s ease-in-out; /* 平滑过渡效果 */
         transition: transform 0.5s linear;
         text-align: left;
      }

.latest_case_news:hover .latest_case_npic{ overflow:hidden; transform: scale(1.3);    transition: all 0.5s;}






  .o_footer{  
    position: relative;
    float: left;
    width:100%; 
    padding:55px 50px 20px 50px;   
    background-color: #000;
    color: #fff;
    margin-top:6px;
  }
  .f-1-2{
      position: relative;
      float: left;
      width:100%;
      height: 50px;
      background-color: #000;
  }
.logo_f{ height: 30px; }

.onexz_name{ clear: both; font-size: 19px; line-height: 70px;}

.onexz_url{ font-size: 20px; }

.address_li li{ list-style: none; margin-top:20px; font-size: 23px; padding: 10px 30px 10px 0;  float:left; margin-right: 50px; cursor: pointer;}

.activetext2{color: #fff;}
.li_c {color:#DE303A;}

.onexz_address{ height: 40px; font-size: 17px;}
.onexz_tel{height: 40px; font-size: 17px;}
.onexz_fax{height: 40px; font-size: 17px;}
.foot_left{width:450px; float: left;}
.else_link{width:100%; height: 40px; margin-top:20px;}
.foot_right{width:350px; float: right;}
.else_link a{ margin-right:40px; }
.tel_f{width:100%; height: 100px; font-size: 25px;}


.fade-enter-active,.fade-leave-active{
    transition: opacity 3s ease;
  }
  .fade-enter-from,.fade-leave-to{
    opacity: 0;
  }
  .fade-enter-to,.fade-leave-from{
    opacity: 1;
  }

  
    /* 定义动画 */
    @keyframes index_pr_left {
      from {
        transform: translateY(100%); /* 从下边开始 */
        opacity: 0; /* 不透明度为0 */
      }
      to {
        transform: translateY(0); /* 移动到原位置 */
        opacity: 1; /* 完全不透明 */
      }
    }


</style>
