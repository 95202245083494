<template>
  <div style="width:100%; height: 100%;">
    <div style="width:100%;height: calc(100% - 50px);">
      <div style="width:100%;height: 80px;background-color: #000;padding: 20px;">
        <img class="logo_f" src="../../../assets/icons/logo_f.png"/>
      </div>
      <div style="width: 100%;height: calc(100% - 90px);">
        <van-tree-select height="100%" :items="case_type" :main-active-index.sync="active">
          <template #content>
            <div style="width:100%;">
              <van-row gutter="8">
                <van-col v-for="(image, index) in works_images" :key="index" span="24">
                  <div style="width:100%;padding: 10px;">
                    <div style="width:100%;overflow: hidden;border: 1px solid #ddd;border-radius: 8px;">
                      <div style="width:100%; ">
                        <van-image width="100%" lazy-load fit="cover":src="image.url"/>
                      </div>
                      <div style="width: 100%; margin-top: 0px; text-align: center;padding: 0px 10px;">
                        <div>{{image.title[0]}}</div>
                          <hr />                       
                        <div>{{image.title[1]}}</div>  
                      </div>
                    </div>
                  </div>
                </van-col>
              </van-row>
            </div>
          </template>
        </van-tree-select>
      </div>
    </div>
    <o_head></o_head>
  </div>
</template>

<script>
  import o_footer  from '../../../components/pages/component/phone_footer.vue'
  import o_head  from '../../../components/pages/component/phone_onexz_head.vue'
  export default {
    props: {
    },
    mounted() {
      document.title = '作品-品牌设计全案-广东万喜至工业设计有限公司'; // 设置页面标题
      window.addEventListener("scroll", this.btn_pos); 
      window.scrollTo(0, 0);
    },
    data() {
      return {
        active:0,       
        case_type:[
            // {'id':0,text:"全部案例",icon:''},
            // {'id':1,text:"激光设备",icon:''},
            // {'id':2,text:"加工中心",icon:''},
            // {'id':3,text:"检测仪器",icon:''},
            // {'id':4,text:"木工机械",icon:''},
            // {'id':5,text:"皮革机械",icon:''},
            // {'id':6,text:"食品设备",icon:''},
            // {'id':7,text:"陶瓷机械",icon:''},
            // {'id':8,text:"橡塑设备",icon:''},
            // {'id':9,text:"鞋子设备",icon:''},
            // {'id':10,text:"医疗设备",icon:''},
            // {'id':11,text:"钣金加工",icon:''},
            // {'id':12,text:"包装印刷",icon:''},
            // {'id':13,text:"玻璃机械",icon:''},
            // {'id':14,text:"纺织服装",icon:''},
            // {'id':15,text:"机器人",icon:''},
            // {'id':16,text:"其他设计",icon:''}
          ],
        scrollTop: 0,
        show:0,
        // works_images: [{"url":"http://www.zq-fa.com/oneXZ_official_website/banner_imgs/1.jpg","name":"yoose有色小冰砖"},{"url":"http://www.zq-fa.com/oneXZ_official_website/banner_imgs/5.jpg","name":"高速加工中心"},{"url":"http://www.zq-fa.com/oneXZ_official_website/banner_imgs/4.jpg","name":"高速加工中心"},
        // {"url":"http://www.zq-fa.com/oneXZ_official_website/banner_imgs/1.jpg","name":"yoose有色小冰砖"},{"url":"http://www.zq-fa.com/oneXZ_official_website/banner_imgs/4.jpg","name":"高速加工中心"},{"url":"http://www.zq-fa.com/oneXZ_official_website/banner_imgs/3.jpg","name":"高速加工中心"},
        // {"url":"http://www.zq-fa.com/oneXZ_official_website/banner_imgs/1.jpg","name":"yoose有色小冰砖"},{"url":"http://www.zq-fa.com/oneXZ_official_website/banner_imgs/3.jpg","name":"高速加工中心"},{"url":"http://www.zq-fa.com/oneXZ_official_website/banner_imgs/5.jpg","name":"高速加工中心"},
        // {"url":"http://www.zq-fa.com/oneXZ_official_website/banner_imgs/1.jpg","name":"yoose有色小冰砖"},{"url":"http://www.zq-fa.com/oneXZ_official_website/banner_imgs/2.jpg","name":"高速加工中心"},{"url":"http://www.zq-fa.com/oneXZ_official_website/banner_imgs/4.jpg","name":"高速加工中心"},
        // {"url":"http://www.zq-fa.com/oneXZ_official_website/banner_imgs/3.jpg","name":"yoose有色小冰砖"},{"url":"http://www.zq-fa.com/oneXZ_official_website/banner_imgs/1.jpg","name":"高速加工中心"},{"url":"http://www.zq-fa.com/oneXZ_official_website/banner_imgs/3.jpg","name":"高速加工中心"},
        // {"url":"http://www.zq-fa.com/oneXZ_official_website/banner_imgs/2.jpg","name":"yoose有色小冰砖"}
        // ],

        // works_images:
        // [{"url":"http://www.zq-fa.com/oneXZ_official_website/works/1.jpg",title:["数控外圆磨床","PRODUCT DESIGN"],"name":["数控外圆磨床",["本款精密数控外圆磨床的设计灵感源自对现代工业制造精度与效率的极致追求。","采用高品质材料和精密制造工艺，旨在为用户带来一款集高效、精准、稳定于一体的工业级磨削解决方案","长久的耐用性，让您的投资更具价值。"],"服务内容：产品设计｜结构设计｜视觉设计｜动画功能"],"infoImgs":['http://www.zq-fa.com/oneXZ_official_website/one_work/b1/info1.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b1/info2.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b1/info3.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b1/info4.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b1/info5.jpg'],"moreImgs":[]},
        //  {"url":"http://www.zq-fa.com/oneXZ_official_website/works/2.jpg",title:["龙门加工中心","PRODUCT DESIGN"],"name":["龙门加工中心",["融合尖端科技与工业美学的精髓，为现代制造业提供一款高效、精准、灵活的加工解决方案。","结合最新的数控技术与精密机械工艺，打造出这款集先进性、实用性、美观性于一身的龙门加工中心。"],"服务内容：产品设计｜结构设计｜视觉设计｜动画功能"],"infoImgs":['http://www.zq-fa.com/oneXZ_official_website/one_work/b2/info1.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b2/info2.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b2/info3.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b2/info4.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b2/info5.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b2/info6.jpg'],"moreImgs":[]},
        //   {"url":"http://www.zq-fa.com/oneXZ_official_website/works/3.jpg",title:["高速加工中心","PRODUCT DESIGN"],"name":["高速加工中心",["具备高速主轴和强大的切削能力，能够实现对材料的快速、精准加工，为用户提供高效、稳定的加工解决方案。","以“高效、精准”为核心设计理念，我们深度融合了最前沿的数控技术和精密机械设计，","确保每一台设备都能成为用户提升生产效率、优化加工品质的重要伙伴。"],"服务内容：产品设计｜结构设计｜视觉设计｜动画功能"],"infoImgs":['http://www.zq-fa.com/oneXZ_official_website/one_work/b3/info1.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b3/info2.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b3/info3.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b3/info4.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b3/info5.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b3/info6.jpg'],"moreImgs":[]},
        //  {"url":"http://www.zq-fa.com/oneXZ_official_website/works/4.jpg",title:["数码标签机","PRODUCT DESIGN"],"name":["数码标签机",["为现代工业及商业环境设计的高精度、高效率自动化打印设备。","它融合了先进的数字打印技术、智能化操作界面与坚固耐用的机械结构，旨在为用户提供一种便捷、精准、灵活的标签解决方案。","通过本产品的应用，企业能够显著提升生产效率，增强品牌形象。"],"服务内容：产品设计｜结构设计｜视觉设计｜动画功能"],"infoImgs":['http://www.zq-fa.com/oneXZ_official_website/one_work/b4/info1.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b4/info2.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b4/info3.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b4/info4.jpg'],"moreImgs":[]},
        //  {"url":"http://www.zq-fa.com/oneXZ_official_website/works/5.jpg",title:["塑料造粒机","PRODUCT DESIGN"],"name":["塑料造粒机",["此设备采用直线式子母组合结构，智能化的技术使生产效率更高；","一体化的结构造型更加简洁；上翻加滑轨门的设计，在安全防护的同时还能切换更大的维修操作空间；","底座向内倾斜，有效提升人机交互体验。通过全新的技术和理念支持塑料行业，为可持续加工做贡献。"],"服务内容：产品设计｜结构设计｜视觉设计｜动画功能"],"infoImgs":['http://www.zq-fa.com/oneXZ_official_website/one_work/b5/info1.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b5/info2.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b5/info3.jpg'],"moreImgs":[]},
        //   {"url":"http://www.zq-fa.com/oneXZ_official_website/works/1.jpg",title:["龙门加工中心","PRODUCT DESIGN"],"name":["龙门加工中心",["融合尖端科技与工业美学的精髓，为现代制造业提供一款高效、精准、灵活的加工解决方案。","结合最新的数控技术与精密机械工艺，打造出这款集先进性、实用性、美观性于一身的龙门加工中心。"],"服务内容：产品设计｜结构设计｜视觉设计｜动画功能"],"infoImgs":['http://www.zq-fa.com/oneXZ_official_website/one_work/b2/info1.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b2/info2.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b2/info3.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b2/info4.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b2/info5.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b2/info6.jpg'],"moreImgs":[]},
        //  {"url":"http://www.zq-fa.com/oneXZ_official_website/works/2.jpg",title:["高速加工中心","PRODUCT DESIGN"],"name":["高速加工中心",["具备高速主轴和强大的切削能力，能够实现对材料的快速、精准加工，为用户提供高效、稳定的加工解决方案。","以“高效、精准”为核心设计理念，我们深度融合了最前沿的数控技术和精密机械设计，","确保每一台设备都能成为用户提升生产效率、优化加工品质的重要伙伴。"],"服务内容：产品设计｜结构设计｜视觉设计｜动画功能"],"infoImgs":['http://www.zq-fa.com/oneXZ_official_website/one_work/b3/info1.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b3/info2.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b3/info3.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b3/info4.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b3/info5.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b3/info6.jpg'],"moreImgs":[]},
        //  {"url":"http://www.zq-fa.com/oneXZ_official_website/works/3.jpg",title:["数码标签机","PRODUCT DESIGN"],"name":["数码标签机",["为现代工业及商业环境设计的高精度、高效率自动化打印设备。","它融合了先进的数字打印技术、智能化操作界面与坚固耐用的机械结构，旨在为用户提供一种便捷、精准、灵活的标签解决方案。","通过本产品的应用，企业能够显著提升生产效率，增强品牌形象。"],"服务内容：产品设计｜结构设计｜视觉设计｜动画功能"],"infoImgs":['http://www.zq-fa.com/oneXZ_official_website/one_work/b4/info1.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b4/info2.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b4/info3.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b4/info4.jpg'],"moreImgs":[]},
        //  {"url":"http://www.zq-fa.com/oneXZ_official_website/works/4.jpg",title:["塑料造粒机","PRODUCT DESIGN"],"name":["塑料造粒机",["此设备采用直线式子母组合结构，智能化的技术使生产效率更高；","一体化的结构造型更加简洁；上翻加滑轨门的设计，在安全防护的同时还能切换更大的维修操作空间；","底座向内倾斜，有效提升人机交互体验。通过全新的技术和理念支持塑料行业，为可持续加工做贡献。"],"服务内容：产品设计｜结构设计｜视觉设计｜动画功能"],"infoImgs":['http://www.zq-fa.com/oneXZ_official_website/one_work/b5/info1.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b5/info2.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b5/info3.jpg'],"moreImgs":[]},
        // ],  

        works_images:
        [
      //     {"url":"http://www.zq-fa.com/oneXZ_official_website/works/1.jpg",title:["数控外圆磨床","PRODUCT DESIGN"],"name":["数控外圆磨床",["本款精密数控外圆磨床的设计灵感源自对现代工业制造精度与效率的极致追求。","采用高品质材料和精密制造工艺，旨在为用户带来一款集高效、精准、稳定于一体的工业级磨削解决方案","长久的耐用性，让您的投资更具价值。"],"服务内容：产品设计｜结构设计｜视觉设计｜动画功能"],"infoImgs":['http://www.zq-fa.com/oneXZ_official_website/one_work/b1/info1.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b1/info2.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b1/info3.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b1/info4.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b1/info5.jpg'],"moreImgs":[]},
      //  {"url":"http://www.zq-fa.com/oneXZ_official_website/index_work/w2.jpg","title":["药品智能管理工作站","PRODUCT DESIGN"],"name":["药品智能管理工作站",["融合尖端科技与工业美学的精髓，为现代制造业提供一款高效、精准、灵活的加工解决方案。","结合最新的数控技术与精密机械工艺，打造出这款集先进性、实用性、美观性于一身的龙门加工中心。"],"服务内容：产品设计｜结构设计｜视觉设计｜动画功能"],"infoImgs":['http://www.zq-fa.com/oneXZ_official_website/one_work/w2/info1.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w2/info2.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w2/info3.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w2/info4.jpg'],"moreImgs":[]},
      //  {"url":"http://www.zq-fa.com/oneXZ_official_website/works/4.jpg",title:["数码标签机","PRODUCT DESIGN"],"name":["数码标签机",["为现代工业及商业环境设计的高精度、高效率自动化打印设备。","它融合了先进的数字打印技术、智能化操作界面与坚固耐用的机械结构，旨在为用户提供一种便捷、精准、灵活的标签解决方案。","通过本产品的应用，企业能够显著提升生产效率，增强品牌形象。"],"服务内容：产品设计｜结构设计｜视觉设计｜动画功能"],"infoImgs":['http://www.zq-fa.com/oneXZ_official_website/one_work/b4/info1.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b4/info2.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b4/info3.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b4/info4.jpg'],"moreImgs":[]},
        
      //   {"url":"http://www.zq-fa.com/oneXZ_official_website/works/2.jpg",title:["龙门加工中心","PRODUCT DESIGN"],"name":["龙门加工中心",["融合尖端科技与工业美学的精髓，为现代制造业提供一款高效、精准、灵活的加工解决方案。","结合最新的数控技术与精密机械工艺，打造出这款集先进性、实用性、美观性于一身的龙门加工中心。"],"服务内容：产品设计｜结构设计｜视觉设计｜动画功能"],"infoImgs":['http://www.zq-fa.com/oneXZ_official_website/one_work/b2/info1.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b2/info2.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b2/info3.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b2/info4.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b2/info5.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b2/info6.jpg'],"moreImgs":[]},
      //     {"url":"http://www.zq-fa.com/oneXZ_official_website/works/3.jpg",title:["高速加工中心","PRODUCT DESIGN"],"name":["高速加工中心",["具备高速主轴和强大的切削能力，能够实现对材料的快速、精准加工，为用户提供高效、稳定的加工解决方案。","以“高效、精准”为核心设计理念，我们深度融合了最前沿的数控技术和精密机械设计，","确保每一台设备都能成为用户提升生产效率、优化加工品质的重要伙伴。"],"服务内容：产品设计｜结构设计｜视觉设计｜动画功能"],"infoImgs":['http://www.zq-fa.com/oneXZ_official_website/one_work/b3/info1.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b3/info2.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b3/info3.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b3/info4.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b3/info5.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b3/info6.jpg'],"moreImgs":[]},
      //   {"url":"http://www.zq-fa.com/oneXZ_official_website/works/5.jpg",title:["塑料造粒机","PRODUCT DESIGN"],"name":["塑料造粒机",["此设备采用直线式子母组合结构，智能化的技术使生产效率更高；","一体化的结构造型更加简洁；上翻加滑轨门的设计，在安全防护的同时还能切换更大的维修操作空间；","底座向内倾斜，有效提升人机交互体验。通过全新的技术和理念支持塑料行业，为可持续加工做贡献。"],"服务内容：产品设计｜结构设计｜视觉设计｜动画功能"],"infoImgs":['http://www.zq-fa.com/oneXZ_official_website/one_work/b5/info1.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b5/info2.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b5/info3.jpg'],"moreImgs":[]},
       
      //  //  {"url":"http://www.zq-fa.com/oneXZ_official_website/index_work/w1.jpg","title":["食品包装机","PRODUCT DESIGN"],"name":["食品包装机",["本款精密数控外圆磨床的设计灵感源自对现代工业制造精度与效率的极致追求。","采用高品质材料和精密制造工艺，旨在为用户带来一款集高效、精准、稳定于一体的工业级磨削解决方案","长久的耐用性，让您的投资更具价值。"],"服务内容：产品设计｜结构设计｜视觉设计｜动画功能"],"infoImgs":['http://www.zq-fa.com/oneXZ_official_website/one_work/w1/info1.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w1/info2.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w1/info3.jpg'],"moreImgs":[]},
      //   {"url":"http://www.zq-fa.com/oneXZ_official_website/index_work/w2.jpg","title":["药品智能管理工作站","PRODUCT DESIGN"],"name":["药品智能管理工作站",["融合尖端科技与工业美学的精髓，为现代制造业提供一款高效、精准、灵活的加工解决方案。","结合最新的数控技术与精密机械工艺，打造出这款集先进性、实用性、美观性于一身的龙门加工中心。"],"服务内容：产品设计｜结构设计｜视觉设计｜动画功能"],"infoImgs":['http://www.zq-fa.com/oneXZ_official_website/one_work/w2/info1.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w2/info2.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w2/info3.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w2/info4.jpg'],"moreImgs":[]},
      //   {"url":"http://www.zq-fa.com/oneXZ_official_website/index_work/w3.jpg","title":["贝尔_造粒机","PRODUCT DESIGN"],"name":["贝尔_造粒机",["具备高速主轴和强大的切削能力，能够实现对材料的快速、精准加工，为用户提供高效、稳定的加工解决方案。","以“高效、精准”为核心设计理念，我们深度融合了最前沿的数控技术和精密机械设计，","确保每一台设备都能成为用户提升生产效率、优化加工品质的重要伙伴。"],"服务内容：产品设计｜结构设计｜视觉设计｜动画功能"],"infoImgs":['http://www.zq-fa.com/oneXZ_official_website/one_work/w3/info1.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w3/info2.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w3/info3.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w3/info4.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w3/info5.jpg'],"moreImgs":[]},
      //   {"url":"http://www.zq-fa.com/oneXZ_official_website/index_work/w4.jpg","title":["全自动测试机","PRODUCT DESIGN"],"name":["全自动测试机",["为现代工业及商业环境设计的高精度、高效率自动化打印设备。","它融合了先进的数字打印技术、智能化操作界面与坚固耐用的机械结构，旨在为用户提供一种便捷、精准、灵活的标签解决方案。","通过本产品的应用，企业能够显著提升生产效率，增强品牌形象。"],"服务内容：产品设计｜结构设计｜视觉设计｜动画功能"],"infoImgs":['http://www.zq-fa.com/oneXZ_official_website/one_work/w4/info1.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w4/info2.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w4/info3.jpg'],"moreImgs":[]},
      //   {"url":"http://www.zq-fa.com/oneXZ_official_website/index_work/w5.jpg","title":["修板机","PRODUCT DESIGN"],"name":["修板机",["此设备采用直线式子母组合结构，智能化的技术使生产效率更高；","一体化的结构造型更加简洁；上翻加滑轨门的设计，在安全防护的同时还能切换更大的维修操作空间；","底座向内倾斜，有效提升人机交互体验。通过全新的技术和理念支持塑料行业，为可持续加工做贡献。"],"服务内容：产品设计｜结构设计｜视觉设计｜动画功能"],"infoImgs":['http://www.zq-fa.com/oneXZ_official_website/one_work/w5/info1.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w5/info2.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w5/info3.jpg'],"moreImgs":[]},
      //   // {"url":"http://www.zq-fa.com/oneXZ_official_website/index_work/w6.jpg","title":["高速飞针测试机","PRODUCT DESIGN"],"name":["高速飞针测试机",["融合尖端科技与工业美学的精髓，为现代制造业提供一款高效、精准、灵活的加工解决方案。","结合最新的数控技术与精密机械工艺，打造出这款集先进性、实用性、美观性于一身的龙门加工中心。"],"服务内容：产品设计｜结构设计｜视觉设计｜动画功能"],"infoImgs":['http://www.zq-fa.com/oneXZ_official_website/one_work/w6/info1.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w6/info2.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/w6/info3.jpg'],"moreImgs":[]},
      //   {"url":"http://www.zq-fa.com/oneXZ_official_website/works/1.jpg",title:["龙门加工中心","PRODUCT DESIGN"],"name":["龙门加工中心",["融合尖端科技与工业美学的精髓，为现代制造业提供一款高效、精准、灵活的加工解决方案。","结合最新的数控技术与精密机械工艺，打造出这款集先进性、实用性、美观性于一身的龙门加工中心。"],"服务内容：产品设计｜结构设计｜视觉设计｜动画功能"],"infoImgs":['http://www.zq-fa.com/oneXZ_official_website/one_work/b2/info1.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b2/info2.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b2/info3.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b2/info4.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b2/info5.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b2/info6.jpg'],"moreImgs":[]},
      //    {"url":"http://www.zq-fa.com/oneXZ_official_website/works/2.jpg",title:["高速加工中心","PRODUCT DESIGN"],"name":["高速加工中心",["具备高速主轴和强大的切削能力，能够实现对材料的快速、精准加工，为用户提供高效、稳定的加工解决方案。","以“高效、精准”为核心设计理念，我们深度融合了最前沿的数控技术和精密机械设计，","确保每一台设备都能成为用户提升生产效率、优化加工品质的重要伙伴。"],"服务内容：产品设计｜结构设计｜视觉设计｜动画功能"],"infoImgs":['http://www.zq-fa.com/oneXZ_official_website/one_work/b3/info1.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b3/info2.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b3/info3.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b3/info4.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b3/info5.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b3/info6.jpg'],"moreImgs":[]},
      //    {"url":"http://www.zq-fa.com/oneXZ_official_website/works/3.jpg",title:["数码标签机","PRODUCT DESIGN"],"name":["数码标签机",["为现代工业及商业环境设计的高精度、高效率自动化打印设备。","它融合了先进的数字打印技术、智能化操作界面与坚固耐用的机械结构，旨在为用户提供一种便捷、精准、灵活的标签解决方案。","通过本产品的应用，企业能够显著提升生产效率，增强品牌形象。"],"服务内容：产品设计｜结构设计｜视觉设计｜动画功能"],"infoImgs":['http://www.zq-fa.com/oneXZ_official_website/one_work/b4/info1.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b4/info2.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b4/info3.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b4/info4.jpg'],"moreImgs":[]},
      //    {"url":"http://www.zq-fa.com/oneXZ_official_website/works/4.jpg",title:["塑料造粒机","PRODUCT DESIGN"],"name":["塑料造粒机",["此设备采用直线式子母组合结构，智能化的技术使生产效率更高；","一体化的结构造型更加简洁；上翻加滑轨门的设计，在安全防护的同时还能切换更大的维修操作空间；","底座向内倾斜，有效提升人机交互体验。通过全新的技术和理念支持塑料行业，为可持续加工做贡献。"],"服务内容：产品设计｜结构设计｜视觉设计｜动画功能"],"infoImgs":['http://www.zq-fa.com/oneXZ_official_website/one_work/b5/info1.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b5/info2.jpg','http://www.zq-fa.com/oneXZ_official_website/one_work/b5/info3.jpg'],"moreImgs":[]},
       
        ],  
        num:0,
        codeWhere:{
          p:1,
          page:200,
          onexzCaseTypeId:0,
        },
      }
    },
    created(){
      let _this=this;
      sessionStorage.li_num = 1;
      this.$getCaseType(function(data){
          _this.case_type=data;
        });
      this.$getOnexzCaseListByTypePage(this.codeWhere,function(data){
        _this.works_images=data.result;
      },);
    },
    components: {
      o_footer,o_head
    },
    methods: {
        btn_pos() {
          let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
          if (scrollTop > 400) {
            this.show=1;        
          } else {
            this.show=0;         
          }      
        },
        
      goToContact_work(imgs) {
        // 编程式导航到 / 路径   
        localStorage.setItem("onework_info",JSON.stringify(imgs));
        this.$router.push({name:'onework'});
      },

      activeIndex(Index){
          this.num= Index;
      },
    
    }
 
  }
</script>
<style>
  .container{
    min-height:100%;
    height: auto !important;
    height: 100%; /*IE6不识别min-height*/
    position: relative;
  }
  
  .page {   
    width:100%;
    margin: 0 auto;  
    margin-top:90px;
    color: #595959;
    font-size: 14px;
  }

  .heard_2 { width: 100%;   padding-top:30px; padding-bottom: 30px; position: fixed; top: 0; z-index: 1000;  padding-left: 5%; background-color: #fff;}
  .logo { float: left; height: 30px; }
  .heard_2  ul li{ float: left; padding: 0 20px 0 20px;list-style: none}
  .heard_2  ul li  {color: #000; font-size:17px; text-shadow: 0px 0px 2px #000000; cursor: pointer;}
  .heard_ul li:hover{ color:#DE303A;  text-shadow: 0px 0px 2px #DE303A;}
  .top_show{opacity: 0; top:-500px; display: none;}
  .top_hide{ opacity: 1; top:0px; display: block;}
  .ch{ display: none}

  .checked_li{ color:#DE303A !important;  text-shadow: 0px 0px 2px #DE303A !important;}
  .No_checked_li{ background-color: none; color:#000;}




  .works_title{
    width: 100%;
    max-width: none;
    min-width: 0px;
    height: auto;
    max-height: none;
    min-height: 86px;
    margin-top: 130px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    padding-left: 5%; 
  }


  .latest_case {
    width: 100%;
    padding: 0;
    margin: 30px 0 15px;
}
.latest_case_more{
   color: #333;
    text-align: left;
    padding: 50px;
    font-weight: 600;
    font-size: 24px;
}
.owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
}

.owl-drag{ padding-bottom: 40px;}        
.p_Product{
        background-color: #fff;
        overflow:hidden; 
        position: relative; 
        display: inline-block;
        vertical-align: top;
        width: calc((100% - 0px) / 3) !important;
    
        border-width: 3px;
        border-style: solid;
        border-color:#fff;
        height: inherit;
        border-radius: 10px;
        transition: all .4s ease-in-out;

}
.latest_case_list{ width:100%; height: auto; float:left;overflow:hidden; }
.latest_case_pic{width: 100%; height: auto; float:left; overflow:hidden; }
.p_Product:hover .latest_case_title{ opacity:1;}
.p_Product:hover .h5_title{ animation: slide-up2 0.5s ease forwards; /* 动画名称，时长，缓动函数，填充模式 */}
.p_Product:hover img{ overflow:hidden; transform: scale(1.1);    transition: all 0.5s;}
   

      
.latest_case_title{position: absolute; top:0; left:0; width:100%; height: 100%;
        background-color: rgba(0,0,0,0.5);
        color:#fff;
        justify-content: center; /* 水平居中 */
        align-items: center; /* 垂直居中 */
        opacity: 0; /*初始时隐藏文本 */
       /* transition: opacity 0.3s ease-in-out; /* 平滑过渡效果 */
         transition: transform 0.5s linear;
      }
  /* 定义动画 */
  @keyframes slide-up2 {
      from {
        transform: translateY(10%); /* 从下边开始 */
        opacity: 0; /* 不透明度为0 */
      }
      to {
        transform: translateY(0); /* 移动到原位置 */
        opacity: 1; /* 完全不透明 */
      }
    }
    

.latest_case_title .h5_title{
  cursor: pointer;
  padding:0 10%  8% 10%;
  text-align: center;
  height: 100%;;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}


.prompt_title{
  width:230px;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}
.seemore_nodata{
  width:100%;
   display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  font-size:18px;
  font-weight: bold;
}
.font_tip{
  padding-left: 10px;
}





/*行业类别css*/

.hc-ul {
    background: #ffffff;
    height: 50px;
    position: relative;
    z-index: 9;
    /* border-style: solid; */
    display: flex;
    justify-content: space-evenly;
    border-radius: 7px;
    margin:0 auto;
  }
.hc-ul li {
    
    float: left;
    width: 230px;
    text-align: center;
    cursor: pointer;
    height: 40px;  
    position: relative;
    z-index: 999;
    list-style-type: none;
    padding: 14px 0 14px 14px;   
    font-size: 15px;
}
.hc-ul li div{
  border-right: 1px solid #dcdbdb; 
  padding: 0 14px 0 0;
}

.hc-ul li:hover{ color:#DE303A; }
.activetext{
  color:#DE303A;
  font-weight: bold;
}























</style>
